import styles from "./PreviewSection.module.css";

export default function PreviewSection({ rootStyles }) {
    return (
        <div className={styles.preview_container} style={rootStyles}>
            <div className={styles.first_row}>
                <p className={styles.heading}>Preview</p>
                <a href="/change" className={styles.change_link}>
                    Change
                </a>
            </div>
            <div>
                <p className={styles.sub_heading}>The ReConvert T-shirt</p>
            </div>
        </div>
    );
}
