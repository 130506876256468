import { Button } from '@chakra-ui/react';
import React, { useContext } from 'react'
import { CDN_BASE_URL } from '../../../config';
import { SECTION_HELP_MODAL_DATA } from '../../../Constant';
import { PageData } from '../../../ContextAPI/context';
import styles from './CustomHeader.module.css'

function CustomHeader({ label, onClose, hideDivider, isHideLink }) {
    const { setHelpModalData } = useContext(PageData)

    const handleClick = () => {
        setHelpModalData(SECTION_HELP_MODAL_DATA[label])
    }

    return (
        <>
            <div className={styles.custom_heading}>
                <h2>{label}</h2>
                {onClose && (
                    <Button onClick={onClose} variant='ghost'>
                        <img src={CDN_BASE_URL + "assets/close.svg"} alt="close button" />
                    </Button>
                )}
            </div>
            {!isHideLink && <span onClick={handleClick} className={'custome-header-link'}>Learn more about {label?.toLowerCase()} </span>}
            {!hideDivider && <div className='content-divider' />}
        </>
    )
}

export default CustomHeader