import { Icon, TextField, Button, ButtonGroup } from "@shopify/polaris";
import { useCallback, useContext, useEffect, useState } from "react";
import { FilterStates } from "./SelectorArea";
import { SearchMinor, CircleTickMinor, ChevronDownMinor } from '@shopify/polaris-icons';
import { getTargetedValue, updateTargetedValue, CreatedNewObj } from "../Actions/common";
import { PageData } from "../ContextAPI/context";
import { UPDATE_STATE } from "../Constant";
import SectionHeader from "./Products/SectionHeader";
import Divider from "./Divider";
import PopoverWithActionList from "./commonComp/PopoverWithActionList";

const systemFontList = [
    "Sans-serif",
    "Serif",
    "Monospace",
]

const otherFontList = [
    "San Francisco",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "San Francisco",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "San Francisco",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue"
]

const fontStyleOption = [
    {
        "items": [
            {
                "content": "Regular",
                "value": "Regular"
            },
            {
                "content": "Italic",
                "value": "Italic"
            },
            {
                "content": "Medium",
                "value": "Medium"
            },
            {
                "content": "Medium italic",
                "value": "Medium italic"
            },
            {
                "content": "Bold",
                "value": "Bold"
            },
            {
                "content": "Bold italic",
                "value": "Bold italic"
            },
            {
                "content": "Extra bold",
                "value": "Extra bold"
            },
            {
                "content": "Extra bold italic",
                "value": "Extra bold italic"
            },
            {
                "content": "Black",
                "value": "Black"
            },
            {
                "content": "Black italic",
                "value": "Black italic"
            }
        ]
    }
]


const SelectFont = (props) => {

    const [searchValue, searchState] = useState('')
    const { filterState } = useContext(FilterStates)
    const { PrePurchaseConfigSetting, dispatch } = useContext(PageData)
    const [selectedStyle, setSelectedStyle] = useState('Regular')
    const [selected, selectedState] = useState({})
    const { data, id, sectionID, topSectionID, handleSave } = props

    useEffect(() => {
        const check = getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], data.target, id, sectionID, topSectionID)
        if (check !== "" && check !== undefined) {
            selectedState({
                font: check,
                id: ""
            })
        }
    }, [PrePurchaseConfigSetting, data, id, sectionID, topSectionID])

    const close = () => {
        filterState({
            status: false,
            component: ''
        })
        document.querySelector(".right-section-title").classList.remove("removeDiv")
        document.querySelector(".links-container")?.classList?.remove("removeDiv")
        document.querySelector(".bottom_container")?.classList?.remove("removeDiv")
    }

    const SearchData = (e) => {
        searchState(e)
    }

    const filterData = useCallback((data) => {
        if (searchValue) {
            const filteredFonts = data.filter((segment) => {
                return segment
                    .toLocaleLowerCase()
                    .includes(searchValue.toLocaleLowerCase()?.trim());
            });
            return filteredFonts
        }
        return data

    }, [searchValue])

    const saveData = async () => {
        const data1 = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
        const dataValue = await updateTargetedValue(data1, data.target, selected.font, id, sectionID, topSectionID)
        dispatch({ type: UPDATE_STATE, payload: dataValue })
        handleSave(selectedStyle)
        filterState({
            status: false,
            component: ''
        })
        document.querySelector(".right-section-title").classList.remove("removeDiv")
        document.querySelector(".links-container")?.classList?.remove("removeDiv")
        document.querySelector(".bottom_container")?.classList?.remove("removeDiv")
    }

    const Cancle = () => {
        selectedState({
            font: "",
            id: ""
        })
    }

    const selectFont = (e, id) => {
        selectedState({
            font: e,
            id
        })
    }

    const handleChange = (e) => {
        setSelectedStyle(e)
    }

    return (
        <>
            <div className='font_selector_container'>

                <SectionHeader title="Select Font" onClose={close} />

                <Divider />

                <div className='font_selector_top_section'>
                    <div className='product_search m-3'>
                        <TextField
                            prefix={<Icon source={SearchMinor} color="base" />}
                            placeholder="Filter"
                            onChange={SearchData}
                            value={searchValue}
                            autoComplete='off'
                        />
                    </div>

                    <div className='specific_product_list'>
                        <ul>
                            <li className="font-list-header-content" key="system-font">
                                <div className='flex-list justify-content-between' >
                                    <div>
                                        <span>SYSTEM FONTS</span>
                                        <p className="font-list-description">These fonts load faster and might appear differently on various devices.</p>
                                    </div>

                                </div>
                            </li>
                            {
                                filterData(systemFontList).length !== 0 ? filterData(systemFontList).map((x, i) => {
                                    return (

                                        <li className="font-list-content" key={'system-font' + i} onClick={() => selectFont(x, `checkIndex${i}`)}>
                                            <div className='flex-list justify-content-between' >
                                                <div>
                                                    <span style={{ fontFamily: `${x}` }} >{x}</span>
                                                </div>
                                                {(selected.font === x) &&
                                                    <div className="px-3">
                                                        <Icon
                                                            source={CircleTickMinor}
                                                            color={"interactive"}
                                                            size="20"
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </li>

                                    )
                                }) :
                                    <div className='text-center'>No match found</div>
                            }
                            <li className="font-list-header-content" key="other-font">
                                <div className='flex-list justify-content-between' >
                                    <div>
                                        <span>OTHER FONTS</span>
                                        <p className="font-list-description">These fonts are downloaded onto a visitor’s computer and might cause slower load times.</p>
                                    </div>

                                </div>
                            </li>
                            {
                                filterData(otherFontList).length !== 0 ? filterData(otherFontList).map((x, i) => {
                                    return (

                                        <li className="font-list-content" key={'other-fonts' + i} onClick={() => selectFont(x, `checkIndex${i}`)}>
                                            <div className='flex-list justify-content-between' >
                                                <div>
                                                    <span style={{ fontFamily: `${x}` }} >{x}</span>
                                                </div>
                                                {(selected.font === x) &&
                                                    <div className="px-3">
                                                        <Icon
                                                            source={CircleTickMinor}
                                                            color={"interactive"}
                                                            size="20"
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </li>
                                    )
                                }) :
                                    <div className='text-center'>No match found</div>
                            }
                        </ul>
                    </div>
                </div>


                <div className='product_bottom_section'>
                    {selected.font === "" ? "" : <div className='flex-list selected-font-container' >
                        <div>
                            <div className="selected-font" style={{ fontFamily: selected.font }}>{selected.font}</div>
                            <div className="font-style-selector">
                                <PopoverWithActionList className='font-style-list-content' options={fontStyleOption} value={selectedStyle} suffix={<Icon source={ChevronDownMinor} />} isHideStyles={true} onChange={handleChange} />
                            </div>
                        </div>
                    </div>}

                    <div className='font_selector_controls'>
                        <ButtonGroup>
                            <Button onClick={Cancle}>Cancel</Button>
                            <Button primary disabled={!selected.font} onClick={saveData}>Save</Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        </>
    )
}

const Typography = (props) => {
    const { data, data_id, sectionID, topSectionID, target } = props
    const { filterIsOpen, filterState } = useContext(FilterStates)
    const { PrePurchaseConfigSetting } = useContext(PageData)
    const [selectedStyle, setSelectedStyle] = useState('Regular')

    const handleSave = (fontStyle) => {
        setSelectedStyle(fontStyle)
    }

    const changeFont = () => {
        document.querySelector(".right-section-title").classList.add("removeDiv")
        document.querySelector(".bottom_container")?.classList?.add("removeDiv")
        document.querySelector(".links-container")?.classList?.add("removeDiv")
        filterState({
            status: true,
            component: <SelectFont data={data} id={data_id} sectionID={sectionID} topSectionID={topSectionID} handleSave={handleSave} />
        })
    }

    const value = getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], target, data_id, sectionID, topSectionID)
    return (
        <>
            {filterIsOpen === true ? null : <div className="component-spacing">
                <div style={{ marginTop: '20px', fontSize: '14px' }}>{data.label}</div>
                <div className='card typography-card'>
                    <div>
                        <div className=''>
                            <h3 style={{ fontFamily: value }}>{value}</h3>
                            <p style={{ fontSize: '14px', marginTop: '3px' }}>{selectedStyle}</p>
                        </div>

                        <div className='mt-3' >
                            <div >
                                <Button
                                    onClick={changeFont}
                                    fullWidth
                                >
                                    Change
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default Typography