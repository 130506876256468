import React, { useContext, useEffect, useState } from 'react';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import '../style/index.css';
import { PageData } from '../ContextAPI/context';
import { CreatedNewObj, getTargetedValue, updateTargetedValue, getTargetedCondition, getSelectedSection } from '../Actions/common';
import { TextEditor } from './commonComp/TextEditor';
import { TITLE, UPDATE_STATE } from '../Constant';
import { Checkbox } from '@shopify/polaris';
import CustomHeader from './commonComp/CustomHeader';

const TitleComp = (props) => {
    const { PrePurchaseConfigSetting, unifiedEditor, dispatch } = useContext(PageData)
    const [titleComp, setTitle] = useState("")
    const [objComp, objstate] = useState([props])
    const { sectionID, topSectionID, target, id, extentionID } = props?.type

    useEffect(() => {
        const fetchData = async () => {
            const latestPrepurchaseSettings = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
            const data = getTargetedValue(latestPrepurchaseSettings, target, id, sectionID, topSectionID, extentionID)
            const obj = await getSelectedSection(data, unifiedEditor.sections)

            objstate(obj)
            setTitle(obj?.title);
        }

        fetchData()
    }, [PrePurchaseConfigSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID]);

    const handleChange = async (e, target) => {
        let value = e;
        const latestPrepurchaseSettings = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
        const updatedPrepurchaseSettings = await updateTargetedValue(latestPrepurchaseSettings, target, value, id, sectionID, topSectionID, extentionID);
        if (updatedPrepurchaseSettings && objComp?.type === TITLE) {
            dispatch({ type: UPDATE_STATE, payload: updatedPrepurchaseSettings });
        }
    };

    try {
        const display = objComp.blockSettings === undefined ? objComp.sectionSettings : objComp.blockSettings

        return (
            <>
                <div className="m-3">
                    {objComp.count === undefined || objComp.count > 0 ?
                        (
                            <>
                                <CustomHeader label={titleComp} />
                                {display.map((x, i) => {

                                    if (x.conditional && getTargetedCondition(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${x.target}`, x.condition, id, sectionID, topSectionID, extentionID) === false) {
                                        return null
                                    } else {
                                        return (
                                            <React.Fragment key={i}>
                                                {x.type === "checkbox" ?

                                                    <div className="ue__icon_set flex items-center justify-content-between">
                                                        <Checkbox
                                                            label={x.label}
                                                            checked={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                                                            onChange={(e) => handleChange(e, `${target}.${x.target}`)}
                                                        />
                                                    </div>
                                                    :
                                                    <TextEditor
                                                        label={x.label}
                                                        tooltipText={x.tooltipText}
                                                        defaultText={x.defaultText}

                                                        value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                                                        onChange={(e) => handleChange(e, `${target}.${x.target}`)}
                                                    />}
                                            </React.Fragment>
                                        )
                                    }
                                }
                                )}
                            </>
                        )
                        : null}
                </div>
            </>
        )
    } catch (err) {
        return (
            <>
                <div className="m-5 text-center text-secondary">Something went wrong..</div>
            </>
        )

    }

}

export default TitleComp;
