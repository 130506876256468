import { Input, InputGroup, InputRightElement, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/react';
import { TextField, Tooltip, Icon } from '@shopify/polaris';
import { QuestionMarkMinor } from '@shopify/polaris-icons';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { WIX } from '../../../Constant';
import { PageData } from '../../../ContextAPI/context';
import styles from './TextField.module.css'
import CustomTooltip from '../CustomTooltip';

function InputField(props) {
    const { onChange, tooltip, toolTipIsEnable, label, autoComplete, multiline, min, max, suffix } = props
    const [value, setValue] = useState(props.value)
    const { type } = useContext(PageData);

    const handleChange = useCallback((e) => {
        setValue(e)
        onChange(e)
    }, [onChange])

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    return (
        <div className={styles.input_wrap}>

            {type === WIX ?
                (
                    <div className="ue__icon_set">
                        <CustomTooltip label={label} text={tooltip} showTooltip={toolTipIsEnable} />

                        {props.type === 'number' ?
                            (
                                <NumberInput onChange={(valueString) => { if (Number(valueString) >= Number(min) && Number(valueString) <= Number(max)) { handleChange(Number(valueString)) } }} value={value} min={min} max={max}>
                                    <InputGroup>
                                        <NumberInputField className={styles.number_input} />
                                        <InputRightElement className={styles.right_element} children={suffix} />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </InputGroup>
                                </NumberInput>
                            )
                            :
                            (
                                <Input
                                    className={styles.input_field}
                                    defaultValue={value}
                                    onChange={(e) => handleChange(e?.target?.value)}
                                    type={props.type}
                                    autoComplete={autoComplete}
                                    multiline={multiline ? 'true' : 'false'}
                                />
                            )
                        }

                    </div>
                )
                :
                (
                    <div className="ue__icon_set">
                        {toolTipIsEnable && (
                            <Tooltip content={tooltip}>
                                <span className="ue_infolabel">
                                    <Icon source={QuestionMarkMinor} color="base" />
                                </span>
                            </Tooltip>
                        )}
                        <TextField
                            multiline={multiline ? true : false}
                            label={label}
                            type={props.type}
                            value={value}
                            onChange={handleChange}
                            autoComplete={autoComplete}
                            min={min}
                            max={max}
                        />

                    </div>
                )
            }
        </div>
    );
}

InputField.prototype = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    autoComplete: PropTypes.string,
    toolTipIsEnable: PropTypes.bool,
    tooltip: PropTypes.string,
    type: PropTypes.string,
    multiline: PropTypes.bool
};

export default InputField;
