import React, { useContext, useState } from "react";
import { PageData } from "../../ContextAPI/context";
import RenderComponents from "./renderComponents";
import { FilterStates } from "../SelectorArea";
import SectionHeader from "../Products/SectionHeader";
import styles from './OfferSettings.module.css'

const Accordion = ({ items, index, sectionID, topSectionID }) => {
    const { extraState, extra, } = useContext(PageData)
    const [isOpen, setOpen] = useState(false);

    let checkLength;
    try {
        checkLength = items.subGroups.length
    } catch (err) {
        checkLength = 0
    }

    let className = items?.label?.replace(" ", "-")

    return (
        <>
            <ul>
                <li key={index} className={checkLength > 0 ? `submenu-outer` : null}>
                    <div className="accordion-offersetting">
                        <div
                            data-component={items.label}
                            data-subcomponent={"main"}
                            onClick={e => {
                                setOpen(true)
                                extraState({
                                    ...extra,
                                    colorPicker: {
                                        status: false,
                                        id: ""
                                    }
                                })
                            }}
                            className="accordion-title settings-title"
                        >
                            {items.label}
                        </div>
                        <span onClick={() => {
                            setOpen(!isOpen)
                            extraState({
                                ...extra,
                                colorPicker: {
                                    status: false,
                                    id: ""
                                }
                            })
                        }} className={` ${isOpen ? "open" : ""}`}></span>
                    </div>
                    <div className={isOpen ? (styles[`${className}-content`] ? styles[`${className}-content`] : styles['settings-content']) : ''}>
                        {
                            items.subGroups.map((x, i) => {
                                return (
                                    <ul key={i} className={`accordion-item sub-menu ${!isOpen ? "collapsed" : "heading-space paddingVal"} `} >
                                        <li key={index} id={`drag${index}`} className=""  >
                                            {x.heading !== "" ? <div className={styles[className] ? styles[className] : "settings-header"}>{x.heading}</div> : null}
                                            {
                                                x.components.map((sub, j) => {
                                                    return (
                                                        <React.Fragment key={j}>
                                                            <RenderComponents className='offer-settings' data={sub} type={sub.type} id={`${sub.type}${i}${j}`} open={isOpen} sectionID={sectionID} topSectionID={topSectionID} />
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </li>
                                    </ul>
                                )
                            })
                        }
                    </div>
                </li>
            </ul>
        </>
    )
};

const OfferSetting = (props) => {
    const { filterIsOpen } = useContext(FilterStates)
    let { unifiedEditor } = useContext(PageData);
    const [contentSelector] = useState(false)
    const data = unifiedEditor
    const { sectionID, topSectionID } = props?.type

    return (

        <div>
            <div className="content_sidebar offer-setting">
                <div className="sidebar-menu-box removeP" style={{ display: filterIsOpen.status === false ? "" : "none" }}>
                    <SectionHeader title={"Offer settings"} rootStyles={{ padding: '1rem', paddingBottom: '0px', paddingLeft: '18px' }} />
                    <div style={{ marginTop: '10px', borderTop: '1px solid #E8E8E8' }} />
                    {contentSelector === true ? null : data.globalSettings.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                {
                                    item.label === "Offer Settings" ? item.settingGroups.map((x, i) => {
                                        return (

                                            <React.Fragment key={i}>
                                                <Accordion
                                                    items={x}
                                                    index={i}
                                                    sectionID={sectionID}
                                                    topSectionID={topSectionID}
                                                />
                                            </React.Fragment>
                                        )
                                    }) : null
                                }
                            </React.Fragment>
                        )
                    })}
                </div>
                {filterIsOpen.component}
            </div>
        </div>

    );
}

export default OfferSetting

