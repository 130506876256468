import React, { useState, useContext, useEffect } from 'react';
import '../style/index.css'
import '../style/customPolaris.css';
import '../style/product.css';
import { PageData } from '../ContextAPI/context'
import { CreatedNewObj, updateTargetedValue, getTargetedValue, getSelectedSection, checkTargetConditions, getTargetString } from '../Actions/common';
import ProductSwitcher from './Products/ProductSwitcher';
import { FilterStates } from "./SelectorArea"
import { UPDATE_STATE } from '../Constant';
import { CDN_BASE_URL } from '../config';
import CustomHeader from './commonComp/CustomHeader';
import InputSelect from './commonComp/InputSelect';

const ProductUpsell = (props) => {
  const [titleComp, setTitle] = useState("")
  const [objComp, objstate] = useState([])
  const [state, setState] = useState()
  const { PrePurchaseConfigSetting, unifiedEditor, dispatch, handleEditorChanges } = useContext(PageData)
  const { filterIsOpen } = useContext(FilterStates)
  const { sectionID, topSectionID, extentionID, target, id, main } = props.type

  useEffect(() => {
    const fetchData = async () => {
      const latestPrepurchaseSettings = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
      const data = getTargetedValue(latestPrepurchaseSettings, target, '', sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections)
      setState(latestPrepurchaseSettings)
      setTitle(obj?.sectionName);
      objstate(obj)
    }

    fetchData()
  }, [PrePurchaseConfigSetting, unifiedEditor, target, sectionID, topSectionID, extentionID]);


  const handleChange = async (e, target, dependedTarget) => {
    let value = e;
    const latestPrepurchaseSettings = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
    let updatedPrepurchaseSettings = await updateTargetedValue(latestPrepurchaseSettings, target, value, id, sectionID, topSectionID, extentionID);

    if (e !== 'specific_product') {
      let string = `defaultSections.[type:${objComp.type}].${dependedTarget}`
      let newValue = getTargetedValue(unifiedEditor, string)
      newValue = JSON.stringify(newValue).replaceAll("{{CDN_BASE_URL}}", CDN_BASE_URL)
      newValue = JSON.parse(newValue)

      let newTarget = getTargetString(target, 2, 2)
      updatedPrepurchaseSettings = await updateTargetedValue(updatedPrepurchaseSettings, `${newTarget}.${dependedTarget}`, newValue, id, sectionID, topSectionID, extentionID);
    }
    let editedData = handleEditorChanges('edit', {sectionID, type:objComp.type})
    dispatch({ type: UPDATE_STATE, payload: {...updatedPrepurchaseSettings, editedData} });
  };

  try {

    return (
      <>

        {<div>
          {filterIsOpen.status === false ?
            <div className='section-details-wrap'>

              <CustomHeader label={titleComp} />

              {
                objComp?.sectionSettings?.map((x, i) => {
                  return (
                    <React.Fragment key={i}>
                      {
                        x.conditional === false ?
                          <InputSelect
                            isShowTooltip={x.showTooltip}
                            tooltipContent={x.tooltipText}
                            label={x.label}
                            options={x.options}
                            onChange={e => handleChange(e, `${target}.${x.target}`, x.dependedTarget)}
                            value={getTargetedValue(state, `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                          />
                          :
                          
                          <ProductSwitcher value={{ obj: x, type: checkTargetConditions(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${x.target}`, x.condition, id, sectionID, topSectionID, extentionID, x.conditionValue), sectionID, topSectionID, extentionID, target: `${target}.${x.target}`, main }} />

                      }
                    </React.Fragment>
                  )

                })
              }
            </div> : filterIsOpen.component}
        </div>}

      </>
    )
  }
  catch (err) {
    return (
      <>
        Error
      </>
    )
  }

}

export default ProductUpsell;