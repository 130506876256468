import React, { useState } from 'react';
import {
  Listbox,
  TextField,
  Icon,
  Popover,
  Scrollable,
  EmptySearchResult,
  Button,
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { CDN_BASE_URL } from '../../config';

const interval = 25;

const PopoverWithSearchbox = (props) => {
  const { iconSource, title, options, handleChange, isHideSearchbar, isAddSection, activatorClass } = props
  const segments = options
  const [pickerOpen, setPickerOpen] = useState(false);
  const [showFooterAction] = useState(true);
  const [query, setQuery] = useState('');
  const [lazyLoading, setLazyLoading] = useState(false);
  const [willLoadMoreResults, setWillLoadMoreResults] = useState(true);
  const [visibleOptionIndex, setVisibleOptionIndex] = useState(6);
  const [filteredSegments, setFilteredSegments] = useState([]);

  const handleFilterSegments = (query) => {
    const nextFilteredSegments = segments.filter((segment) => {
      return segment.label
        .toLocaleLowerCase()
        .includes(query.toLocaleLowerCase()?.trim());
    });

    setFilteredSegments(nextFilteredSegments);
  };

  const handleQueryChange = (query) => {
    setQuery(query);

    if (query.length >= 1) handleFilterSegments(query);
  };

  const handleQueryClear = () => {
    handleQueryChange('');
  };

  const handleOpenPicker = () => {
    setPickerOpen(!pickerOpen);
  };

  const handleClosePicker = () => {
    setPickerOpen(false);
    handleQueryChange('');
  };

  const handleSegmentSelect = (segmentIndex) => {
    handleChange(segmentIndex)
    handleClosePicker();
  };

  const handleActiveOptionChange = () => {
  };

  /* This is just to illustrate lazy loading state vs loading state. This is an example, so we aren't fetching from GraphQL. You'd use `pageInfo.hasNextPage` from your GraphQL query data instead of this fake "willLoadMoreResults" state along with setting `first` your GraphQL query's variables to your app's default max edges limit (e.g., 250). */

  const handleLazyLoadSegments = () => {
    if (willLoadMoreResults && !showFooterAction) {
      setLazyLoading(true);

      const options = query ? filteredSegments : segments;

      setTimeout(() => {
        const remainingOptionCount = options.length - visibleOptionIndex;
        const nextVisibleOptionIndex =
          remainingOptionCount >= interval
            ? visibleOptionIndex + interval
            : visibleOptionIndex + remainingOptionCount;

        setLazyLoading(false);
        setVisibleOptionIndex(nextVisibleOptionIndex);

        if (remainingOptionCount <= interval) {
          setWillLoadMoreResults(false);
        }
      }, 1000);
    }
  };

  const listboxId = 'SearchableListboxInPopover';

  /* Your app's feature/context specific activator here */
  const activator = (
    <div className={`activator-container ${isAddSection && 'add-section-padding'} ${pickerOpen && 'active-button-container'} ${activatorClass ? activatorClass : ''}`}>
      <Button onClick={handleOpenPicker} icon={<Icon source={iconSource} />} >
        {title}
      </Button>
    </div>
  );

  const textFieldMarkup = (
    <div style={{ padding: '8px' }}>
      <StopPropagation>
        <TextField
          focused={showFooterAction}
          clearButton
          labelHidden
          label="Customer segments"
          placeholder="Search segments"
          autoComplete="off"
          value={query}
          prefix={<Icon source={SearchMinor} />}
          ariaControls={listboxId}
          onChange={handleQueryChange}
          onClearButtonClick={handleQueryClear}
        />
      </StopPropagation>
    </div>
  );

  const optionTitle = (
    <div className='option-title'>
      {props.optionTitle}
    </div>

  )

  const segmentOptions = query ? filteredSegments : segments;

  const segmentList =
    segmentOptions.length > 0
      ? segmentOptions
        .map(({ label, icon, value }) => {

          return (
            <Listbox.Option key={value} value={value} selected={false}>
              <Listbox.TextOption selected={false}>
                {icon && <img className='list-icon' src={CDN_BASE_URL + `assets/${icon}`} alt={`${label} icon`} />}{label}
              </Listbox.TextOption>
            </Listbox.Option>
          );
        })
      : null;



  const lazyLoadingMarkup = lazyLoading ? (
    <Listbox.Loading
      accessibilityLabel={`${query ? 'Filtering' : 'Loading'
        } customer segments`}
    />
  ) : null;

  const noResultsMarkup =
    segmentOptions.length === 0 ? (
      <EmptySearchResult
        title=""
        description={`No segments found matching "${query}"`}
      />
    ) : null;

  const listboxMarkup = (
    <Listbox
      enableKeyboardControl
      accessibilityLabel="Search for and select a customer segment"
      customListId={listboxId}
      onSelect={handleSegmentSelect}
      onActiveOptionChange={handleActiveOptionChange}
      autoSelection={'NONE'}
    >
      {segmentList}
      {noResultsMarkup}
      {lazyLoadingMarkup}
    </Listbox>
  );

  return (
    <div style={{ height: '38px' }}>
      {pickerOpen &&
        <style jsx='false' global='false'>{`
          .Polaris-Popover__Wrapper{
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }
          
          img, svg, video, canvas, audio, iframe, embed, object {
            display: initial;
          }
        `}</style>
      }
      <Popover
        active={pickerOpen}
        activator={activator}
        ariaHaspopup="listbox"
        preferredAlignment="left"
        onClose={handleClosePicker}
      >
        <Popover.Pane fixed>
          <div
            style={{
              alignItems: 'stretch',
              borderTop: '1px solid #DFE3E8',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'stretch',
              position: 'relative',
              width: '100%',
              height: '100%',
              overflow: 'hidden'
            }}
          >
            {!isHideSearchbar && textFieldMarkup}

            <Scrollable
              shadow
              className='popover-list-container'
              style={{
                position: 'relative',
                width: '310px',
                maxHeight: 'calc(100vh - 8px - 298px)',
                borderBottomLeftRadius: 'var(--p-border-radius-2)',
                borderBottomRightRadius: 'var(--p-border-radius-2)',
                overflowY: 'scroll'
              }}
              onScrolledToBottom={handleLazyLoadSegments}
            >
              {optionTitle}
              {listboxMarkup}
            </Scrollable>
          </div>
        </Popover.Pane>
      </Popover>
    </div >
  );
}

const StopPropagation = ({ children }) => {
  const stopEventPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div onClick={stopEventPropagation} onTouchStart={stopEventPropagation}>
      {children}
    </div>
  );
};


export default PopoverWithSearchbox;