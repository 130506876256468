import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import '../style/theme-block-section.css'
import { PageData } from "../ContextAPI/context";
import { CDN_BASE_URL } from "../config";
import { getTargetedValue, Ids, CreatedNewObj, updateTargetedValue, getTargetedCondition, getSections, getGroupData, getTargetString, makeRightbarLinks, assignIdsToBlocks, getOptions, getUniqueUpsellName, getTargeted, editRightbarLinks, } from "../Actions/common";
import Divider from "./Divider";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import LeftbarSkeleton from "./LeftBarSkeleton";
import { BANNER_LABEL, CHECKOUT, PREPURCHASE, STATIC_EXTENTION_ID, UPDATE_STATE, WIX } from "../Constant";
import PreviewSection from "./PreviewSection/PreviewSection";
import WarningBanner from "./WarningBanner";
import AddSectionDropdown from "./commonComp/AddSectionDropdown";
import WixModal from "./commonComp/WixModal";
import { ViewMinor, DragHandleMinor, CirclePlusMinor, HideMinor, CaretDownMinor, PlusMinor, DeleteMinor } from "@shopify/polaris-icons";
import { Button, Icon } from "@shopify/polaris";
import PopoverWithSearchbox from "./commonComp/PopoverWithSearchbox";
import ModalComponent from "./commonComp/Modal";
import { ShopifyReviewLeftbarWidget } from "./ReviewCollector/ReviewCollector";

const _ = require('lodash');

const AddBlock = (props) => {
    /*
      add block button to add blocks in sections
   */

    const { isOpen, addblock, openId, id, sectionID, topSectionID, target, extentionID, sectionType, location } = props
    const { unifiedEditor, PrePurchaseConfigSetting, dispatch, setRightbarLinks, rightbarLinks, type, isRequiredDataFilled, handleEditorChanges } = useContext(PageData)
    const [uniqueAddBlock, blockState] = useState([])

    const { setOpenId } = openId || {};

    const uniqueValues = useCallback(() => {
        let checking = []
        checking = addblock.reduce((x, y, index) => {
            if (y.count < y.maxCount) {
                const check = x.find(obj => obj.blockName === y.blockName)
                if (check === undefined) {
                    x.push({ ...y, index: index, label: y.blockName, value: y.id, media: <img src={CDN_BASE_URL + `assets/${y.icon}`} alt={y.icon} /> })
                }
            }
            return x
        }, [])
        blockState(checking)
    }, [addblock])

    useEffect(() => {
        setOpenId((prevOpenId) => ({
            ...prevOpenId,
            id: !id,
        }));

        uniqueValues('')
    }, [unifiedEditor, addblock, id, uniqueValues, setOpenId])

    const handleChange = (ids) => {
        if (isRequiredDataFilled()) {
            let latest = _.cloneDeep(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
            let obj = uniqueAddBlock.find(item => item.id === ids)
            let string = `defaultSections.[type:${sectionType}].blocks.[type:${obj.type}]`
            let id = Ids().toString()
            let value = getTargetedValue(unifiedEditor, string, id, sectionID, topSectionID, extentionID)
            if (value.isHideBlock) {
                delete value['isHideBlock']
            }
            let pre_purchase = getTargetedValue(latest, `${target}.blocks`, id, sectionID, topSectionID, extentionID)

            if (location) {
                value.location = location
            }

            pre_purchase.splice(obj.index, 0, { ...value, id: id, })

            let sectionObj = { sectionID, type: sectionType }
            let editedData = handleEditorChanges('edit', sectionObj)
            dispatch({ type: UPDATE_STATE, payload: { ...latest, editedData } })

            if (rightbarLinks?.[0]?.parentSectionID === sectionID) {
                let newArr = _.cloneDeep(addblock)
                newArr[obj.index] = { ...newArr[obj.index], count: newArr[obj.index].count + 1 }
                let links = makeRightbarLinks(newArr, sectionID, 'block')
                setRightbarLinks(links)
            }
        }
    }

    return (
        <>
            {uniqueAddBlock?.length > 0 ?
                <ul>
                    <li className={`add_block sub-menu ${!isOpen ? "collapsed" : ""}`}>
                        {type === WIX ?
                            <AddSectionDropdown
                                isLink={true}
                                isBlock={true}
                                title={'Add block'}
                                optionTitle={'ADD SECTION'}
                                options={uniqueAddBlock || []}
                                handleChange={handleChange}
                            />
                            :
                            <PopoverWithSearchbox options={uniqueAddBlock} optionTitle={'ADD SECTION'} isHideSearchbar={true} handleChange={handleChange} iconSource={CirclePlusMinor} title={'Add block'} />
                        }
                    </li>
                </ul>
                :
                <div className={`disabled-add-button`}>
                    {type === WIX ?
                        <div className='wix-disabeld-add-btn'>
                            <img src={CDN_BASE_URL + `assets/plus-disabled.svg`} alt={`plus icon`} />
                            Add block ({addblock.length}/ {addblock.length})
                        </div>
                        :
                        <Button icon={<Icon source={CirclePlusMinor} />} >
                            Add block ({addblock.length}/ {addblock.length})
                        </Button>
                    }
                </div>
            }
        </>
    )
}

const VisibilityButtonGroup = ({ item, sectionID, topSectionID, extentionID, provided, handleMouseEvent, isBlock, target, onClick }) => {
    /*
      Common component
      render group of button which contains visisbility and drag handler buttons
   */

    const { PrePurchaseConfigSetting, dispatch, previewSelectedSections, rightbarLinks, setRightbarLinks, type, isRequiredDataFilled, handleEditorChanges } = useContext(PageData)
    const [active, setActive] = useState(false);
    const buttonRef = useRef()
    const [isSelected, setIsSelected] = useState(false)
    const [state, setState] = useState(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])

    useEffect(() => {
        setState(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
    }, [PrePurchaseConfigSetting])

    const hideHandle = async (event) => {
        event.stopPropagation();
        let value = isTargetVisible()
        const data = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
        const dataValue = await updateTargetedValue(data, `${item.target}.${item.condition}`, value, item.id, sectionID, topSectionID, extentionID)
        let sectionObj = { sectionID, type: isBlock ? 'product_upsell' : item.type }
        let editedData = handleEditorChanges('edit', sectionObj)
        dispatch({ type: UPDATE_STATE, payload: { ...dataValue, editedData } })
    }

    const isTargetVisible = useCallback(() => {
        return getTargetedCondition(state, `${item.target}.${item.condition}`, item.condition, item.id, sectionID, topSectionID, extentionID) === false
    }, [state, item, sectionID, topSectionID, extentionID])

    const handleMouseDown = (e) => {
        if (handleMouseEvent) {
            if (isRequiredDataFilled()) {
                handleMouseEvent(e)
            }
        }
    }

    const handleModalToggale = (event) => {
        event?.stopPropagation();
        if (active) {
            setIsSelected(false)
        } else {

            let isSelected = false
            if (isBlock) {
                const isSameBlock = event.target.closest(".accordion-content").classList.contains('selected-item-active')
                isSelected = isSameBlock
            } else {
                isSelected = previewSelectedSections?.id === item?.id
            }

            setIsSelected(isSelected)

        }
        setActive((active) => !active)
    }

    const handleDelete = (event) => {
        event?.stopPropagation();
        let key = isBlock ? 'id' : 'sectionID'
        removeElement(key, item.id, target)
    }

    const removeElement = (key, id, targetStr, isRemoved) => {
        let latestData = _.cloneDeep(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
        const previewData = getTargeted(latestData, targetStr, 1, id, sectionID, topSectionID, extentionID)
        let index = previewData.findIndex((obj) => Number(obj[key]) === Number(id))
        if (index >= 0) {
            if (previewData.length === 1 && !isRemoved) {
                let newTarget = targetStr.split('.');
                newTarget.splice(newTarget.length - 2, 2);
                removeElement(key, topSectionID, newTarget.join('.'), true)
            } else {
                previewData.splice(index, 1);
                dispatch({ type: UPDATE_STATE, payload: latestData });

                if (isSelected || (isRemoved && (previewSelectedSections?.parentId === id || previewSelectedSections?.id === id))) {
                    onClick();
                } else {
                    let matchingId = isBlock ? sectionID : topSectionID
                    if (rightbarLinks?.[0]?.parentSectionID === matchingId) {
                        let arr = _.cloneDeep(rightbarLinks)
                        arr = arr.filter((obj) => obj.id !== id)
                        arr = editRightbarLinks(arr, isBlock ? 'block' : 'section')
                        setRightbarLinks(arr)
                    }
                }
                setActive((active) => !active)
            }

        }
    }

    return (
        <>
            <div className="icon-bundle">
                {item.target && state && (
                    isTargetVisible() ?
                        <span style={!item.isDraggable ? { paddingRight: '4px' } : {}} className="close-eye-icon" onClick={hideHandle}>
                            {type === WIX ?
                                <img className="eye-icon" src={CDN_BASE_URL + `assets/hideMinor.svg`} alt={`hide icon`} />
                                :
                                <Icon source={HideMinor} color="base" />
                            }
                        </span>
                        :
                        <span className="visible" onClick={hideHandle}>
                            {type === WIX ?
                                <img className="eye-icon" src={CDN_BASE_URL + `assets/viewMinor.svg`} alt={`view icon`} />
                                :
                                <Icon source={ViewMinor} color="base" />
                            }
                        </span>
                )}

                {item.isRemovable &&
                    <span className="visible grab" ref={buttonRef} onClick={handleModalToggale}>
                        <div className="delete-icon-wrapper">
                            {type === WIX ?
                                <img src={CDN_BASE_URL + `assets/delete-icon.svg`} alt={`delete icon`} />
                                :
                                <Icon source={DeleteMinor} color="base" />
                            }
                        </div>
                    </span>
                }

                {item.isDraggable &&
                    <span className="visible grab" {...provided?.dragHandleProps} onMouseDown={handleMouseDown}>
                        <div className="grab-icon-wrapper">
                            {type === WIX ?
                                <img src={CDN_BASE_URL + `assets/drag-icon.svg`} alt={`drag icon`} />
                                :
                                <Icon source={DragHandleMinor} color="base" />
                            }
                        </div>
                    </span>
                }
            </div>
            {active &&
                <>
                    {type === WIX ?
                        <WixModal
                            active={active}
                            handleClose={handleModalToggale}
                            handleSubmit={handleDelete}
                            primaryBtnLabel='Yes'
                            secondaryBtnLabel='No'
                            title='Delete confirmation'
                            content={`Are you sure to remove this ${isBlock ? 'block' : 'section'}?`}
                        />
                        :
                        <ModalComponent
                            active={active}
                            handleClose={handleModalToggale}
                            handleSubmit={handleDelete}
                            primaryBtnLabel='Yes'
                            secondaryBtnLabel='No'
                            title='Delete confirmation'
                            buttonRef={buttonRef}
                            content={`Are you sure to remove this ${isBlock ? 'block' : 'section'}?`}
                        />
                    }
                </>
            }
        </>
    )
}


const Ul = (props) => {
    /*
            Render blocks
            */
    const { item, index, items, onClick, isOpen, topSectionID, extentionID, isHideVisibility, provided, } = props
    const { selectedLink, setSelectedLink, setRightbarLinks, setPreviewSelectedSection, isRequiredDataFilled } = useContext(PageData)
    const blockRef = useRef();

    useEffect(() => {
        if (selectedLink?.id && selectedLink?.id === item?.id) {
            blockRef.current.click()
            setSelectedLink({})
        }
    }, [selectedLink, setSelectedLink, item?.id])

    const handleBlockClick = async (e) => {
        const isSameBlock = e.target.closest(".accordion-content").classList.contains('selected-item-active')
        Array.from(document.querySelectorAll('.selected-item-active')).forEach((el) => el.classList.remove('selected-item-active'));

        if (isSameBlock) {
            setPreviewSelectedSection({ id: null, parentId: null })
            onClick()
        } else {
            e.target.closest(".accordion-content").classList.add("selected-item-active");
            setPreviewSelectedSection({ id: items.id, parentId: topSectionID, isBlock: true })
        }

        setRightbarLinks([])
    }

    return (
        <React.Fragment key={index}>
            {item.maxCount >= item.count && item.count === 0 ? null :
                <ul className={` sub-menu ${!isOpen ? "collapsed" : ""}`} key={index} >
                    <li ref={blockRef} key={index} id={`drag${index}`} className="accordion-content sub_list_data" data-id={item.id} data-section-id={items.id} data-top-section-id={topSectionID} data-extention-id={extentionID} data-target={item.target} data-count={item.count} data-mincount={item.minCount} data-subcomponent={items.sectionName} data-component={item.blockName} onClick={(e) => { if (isRequiredDataFilled()) { onClick(e); handleBlockClick(e); } }} >
                        <span className="block-title-span">
                            <span className="block-title">
                                {item.icon && <img src={CDN_BASE_URL + `assets/${item.icon}`} alt={`${item.blockName} icon`} />}
                                <div>{item.blockName}</div>
                            </span>
                        </span>
                        {!isHideVisibility && (item.count !== item.minCount) && <VisibilityButtonGroup provided={provided} item={item} sectionID={items.id} topSectionID={topSectionID} extentionID={extentionID} isBlock={true} target={item.target} onClick={onClick} />}
                    </li>
                </ul>}
        </React.Fragment>
    )
}

const AddSection = ({ sections, defaultOptions, topSectionID, target, extentionID, setNewSectionID }) => {
    /*
            Add section button to add section in top level sections
            */

    const [options, setOptions] = useState([])
    const { dispatch, PrePurchaseConfigSetting, unifiedEditor, isRequiredDataFilled, type, handleEditorChanges } = useContext(PageData)

    useEffect(() => {
        const fetchOptions = async () => {
            if (defaultOptions) {
                let items = await getOptions(defaultOptions, unifiedEditor.sections)
                setOptions(items.map((i) => { return { label: i.sectionName, value: i.type, icon: i.icon, } }))
            }
        }

        fetchOptions()
    }, [defaultOptions, sections, unifiedEditor.sections])

    const handleChange = (e) => {
        if (isRequiredDataFilled()) {
            let latest = _.cloneDeep(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
            let selectedSection = e
            let str = `.sections`
            let string = `defaultSections.[type:${selectedSection}]`

            let id = Ids() + Math.floor(Math.random() * 10000)
            let obj = getTargetedValue(unifiedEditor, string)
            let pre_purchase = getTargetedValue(latest, target + str, selectedSection, '', topSectionID, extentionID)

            let pre_purchase_new = _.cloneDeep(obj)
            pre_purchase_new.sectionID = id
            pre_purchase_new.isVisible = true
            pre_purchase_new = JSON.stringify(pre_purchase_new).replaceAll("{{CDN_BASE_URL}}", CDN_BASE_URL)
            pre_purchase_new = JSON.parse(pre_purchase_new)
            pre_purchase.push(pre_purchase_new)
            setNewSectionID(id)

            let editedData = handleEditorChanges('add', [pre_purchase_new])
            dispatch({ type: UPDATE_STATE, payload: { ...latest, sectionAdded: true, editedData } })
        }
    }

    return (
        <ul>
            {options.length > 0 ?
                <li className={`add-section-wrap sub-menu collapsed`}>
                    {type === WIX ?
                        <AddSectionDropdown
                            isLink={true}
                            title={'Add section'}
                            optionTitle={'ADD SECTION'}
                            options={options || []}
                            handleChange={handleChange}
                        />
                        :
                        <PopoverWithSearchbox options={options} optionTitle={'ADD SECTION'} isHideSearchbar={true} handleChange={handleChange} iconSource={CirclePlusMinor} title={'Add Section'} />
                    }
                </li>
                :
                <div className={`disabled-add-button`}>
                    {type === WIX ?
                        <>
                            <img src={CDN_BASE_URL + `assets/plus-disabled.svg`} alt={`plus icon`} />
                            Add section
                        </>
                        :
                        <Button icon={<Icon source={CirclePlusMinor} />} >
                            Add section
                        </Button>
                    }
                </div>
            }
        </ul>

    )
}

const Accordion = ({ items, index, onClick, addblock, topSectionID, extentionID, location, target, isHideVisibility, provided, isDragging, isDefaultOpen, isParentOpen }) => {
    /*
            Rendering section which may contains blocks
            */

    const divRef = useRef()
    const [isOpen, setOpen] = useState(false);
    const [openId, setOpenId] = useState({ id: "", status: false })
    const [dataCheck, itemState] = useState({})
    const [isHide, setIsHide] = useState(false)
    const { unifiedEditor, PrePurchaseConfigSetting, dispatch, selectedSection, setSelectedSection, selectedLink, setSelectedLink, setRightbarLinks, previewSelectedSections, setPreviewSelectedSection, type, isRequiredDataFilled } = useContext(PageData)
    let checkLength;
    try {
        checkLength = items.blocks.length
    } catch (err) {
        checkLength = 0
    }

    useEffect(() => {
        if (isDefaultOpen && divRef.current) {
            divRef.current.click()
        }
    }, [isDefaultOpen])

    useEffect(() => {
        if (isDefaultOpen && !selectedSection?.id) {
            setOpen(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDefaultOpen])

    useEffect(() => {
        if (isParentOpen) {
            setOpen(true)
        }
    }, [isParentOpen])

    useEffect(() => {
        if (selectedSection?.id === items?.id && divRef.current) {
            setOpen(selectedSection.isOpen)
            setIsHide(false)
            divRef.current.click()
            setSelectedSection()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])


    useEffect(() => {
        if (selectedLink?.id === items?.id && divRef.current) {
            divRef.current.click()
            setSelectedLink({})
        } else if (selectedLink?.parentSectionID === items?.id) {
            setSelectedLink({})
            setOpen(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLink])

    const handleSectionClick = (e) => {
        Array.from(document.querySelectorAll('.selected-item-active')).forEach((el) => el.classList.remove('selected-item-active'));

        if (selectedSection?.id !== items?.id && previewSelectedSections.id === items.id && !previewSelectedSections.isBlock) {
            setPreviewSelectedSection({ id: null, parentId: null })
            onClick()
        } else {
            e.target.closest(".accordion-title-outer").classList.add("selected-item-active");
            let links = makeRightbarLinks(items?.blocks?.length > 0 ? items?.blocks : [], items.id, 'block', dataCheck?.seprators?.length > 0)
            setPreviewSelectedSection({ id: items.id, parentId: topSectionID })
            setRightbarLinks(links)
            if (isHide) {
                setIsHide(false)
            } else {
                if (selectedSection?.id) {
                    setOpen(selectedSection.isOpen)
                } else {
                    setOpen(true)
                }
            }
        }


    }

    useEffect(() => {
        itemState(items)
    }, [unifiedEditor, PrePurchaseConfigSetting, items])

    const handleDragEnd = (result, items) => {
        if (!result.destination) return;
        let target = items.target
        let latestData = _.cloneDeep(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
        let arr = getTargetedValue(latestData, `${target}.blocks`, "", items.id, topSectionID, extentionID)
        const element = arr.splice(result.source.index, 1)[0];
        arr.splice(result.destination.index, 0, element);
        dispatch({ type: UPDATE_STATE, payload: latestData });
    }

    const handleMouseEvent = (e) => {
        e.stopPropagation();
        setIsHide(true)
        setSelectedSection({ id: items?.id, isOpen: isOpen })
    }

    const handleDropdownClick = (e) => {
        e.stopPropagation();
        if (isHide) {
            setIsHide(false)
            setOpen(true)
        } else {
            setOpen(!isOpen)
        }
    }

    return (
        <ul key={index} style={{ backgroundColor: isDragging ? 'white' : 'none', boxShadow: isDragging ? '2px 2px 5px lightgrey' : 'none' }}>
            <li className={checkLength > 0 ? `submenu-outer` : null}>
                <div
                    className="accordion-title-outer"
                    data-component={items.sectionName}
                    data-location={location}
                    data-subcomponent={"main"}
                    data-section-id={items.id}
                    data-top-section-id={topSectionID}
                    data-extention-id={extentionID}
                    data-target={target}
                    data-count={items.count}
                    data-mincount={items.minCount}
                    ref={divRef}
                    onClick={e => {
                        if (isRequiredDataFilled()) {
                            onClick(e);
                            handleSectionClick(e);
                        }
                    }}
                >
                    <div className="accordion-title-container" >
                        {
                            items?.blocks?.length
                                ?
                                <div onClick={(e) => { handleDropdownClick(e) }} className={`accordion-dropdown-icon ${!isHide || isOpen ? "open" : ""}`}>
                                    <span className={!isHide && isOpen ? "accordion-dropdown-icon-open" : "accordion-dropdown-icon-close"}>
                                        {type === WIX ?
                                            <img src={CDN_BASE_URL + `assets/caret-down.svg`} alt={`plus icon`} />
                                            :
                                            <Icon source={CaretDownMinor} />
                                        }
                                    </span>
                                </div>
                                :
                                <div style={{ display: 'none' }}>
                                    <span>
                                        {type === WIX ?
                                            <img src={CDN_BASE_URL + `assets/caret-down.svg`} alt={`plus icon`} />
                                            :
                                            <Icon source={CaretDownMinor} />
                                        }
                                    </span>
                                </div>
                        }

                        <span
                            style={items?.blocks?.length && { paddingLeft: '0px' }}
                            className="accordion-title-span accordion-title"
                        >
                            {dataCheck.icon && <img src={CDN_BASE_URL + `assets/${dataCheck.icon}`} alt={`${dataCheck.sectionName} icon`} />}
                            {dataCheck.sectionName}
                        </span>
                    </div>
                    {!isHideVisibility && <VisibilityButtonGroup handleMouseEvent={handleMouseEvent} provided={provided} item={dataCheck} sectionID={dataCheck.id} topSectionID={topSectionID} extentionID={extentionID} target={target} onClick={onClick} />}
                </div>

                <div className={`${!isOpen || isHide ? "collapsed none" : "list"}`} >
                    <DragDropContext onDragEnd={(e) => handleDragEnd(e, items)} >
                        <Droppable droppableId={`${items.id}`}>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                >
                                    {dataCheck.blocks?.map((item, i) => {
                                        return (
                                            <Draggable key={item.id} draggableId={`${item.id}`} index={i} >
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} key={i}    >

                                                        <Ul
                                                            item={item}
                                                            items={dataCheck}
                                                            isHideVisibility={isHideVisibility}
                                                            index={i}
                                                            onClick={onClick}
                                                            isOpen={isOpen}
                                                            topSectionID={topSectionID}
                                                            extentionID={extentionID}
                                                            provided={provided}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {dataCheck.blocks && !isHideVisibility &&
                        <AddBlock
                            isOpen={isOpen}
                            addblock={addblock}
                            id={`addblock${""}`}
                            blockType={''}
                            sectionType={dataCheck.type}
                            topSectionID={topSectionID}
                            sectionID={dataCheck.id}
                            openId={{ openId, setOpenId }}
                            extentionID={extentionID}
                            target={target}
                        />
                    }
                </div>

            </li>
        </ul>
    );
};

const Section = ({ items, onClick, topSectionID, isHideAddSection, extentionID, isHideVisibility, isDefaultOpen, options, target, isOpen }) => {
    const { PrePurchaseConfigSetting, dispatch, requiredData } = useContext(PageData)
    const [newSectionID, setNewSectionID] = useState(false)
    const [isDragDisabled, setIsDragDisabled] = useState([])

    useEffect(() => {
        setIsDragDisabled(requiredData.stopChanges)
    }, [requiredData.stopChanges])

    const handleDragEnd = (result, items) => {
        if (!result.destination) return;
        let target = items[0].target
        let latestData = _.cloneDeep(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
        target = target.split('.');
        target.splice(target.length - 1, 1)
        let arr = getTargetedValue(latestData, target.join('.'), "", "", topSectionID, extentionID)
        const element = arr.splice(result.source.index, 1)[0];
        arr.splice(result.destination.index, 0, element);
        dispatch({ type: UPDATE_STATE, payload: latestData });
    }

    return (
        <>
            <DragDropContext onDragEnd={(e) => handleDragEnd(e, items)} >
                <Droppable droppableId={`${extentionID}`}>
                    {(provided) => (
                        <div ref={provided.innerRef}  >
                            {items === undefined ? null : items?.map((item, index) => {
                                return (
                                    <Draggable
                                        key={`${item.id}`}
                                        draggableId={`${item.id ? item.id : Ids()}`}
                                        index={index}
                                        isDragDisabled={isDragDisabled}
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <Accordion
                                                    items={item}
                                                    index={index}
                                                    provided={provided}
                                                    onClick={onClick}
                                                    addblock={item.blocks}
                                                    topSectionID={topSectionID}
                                                    extentionID={extentionID}
                                                    target={item.target}
                                                    isHideVisibility={isHideVisibility}
                                                    isDefaultOpen={isDefaultOpen || newSectionID === item?.id}
                                                    isParentOpen={isOpen}

                                                />
                                            </div>
                                        )}
                                    </Draggable>

                                );
                            })
                            }
                            {provided.placeholder}
                        </div>
                    )
                    }
                </Droppable>
            </DragDropContext>
            {items?.length > 0 && !isHideAddSection && <AddSection defaultOptions={options} setNewSectionID={setNewSectionID} sections={items} target={target} extentionID={extentionID} topSectionID={topSectionID} />}
        </>
    )
}

const TopLevelSection = ({ data, onClick, isHideAddSection, extentionID, location, target, isHideVisibility, provided, isDragging, isDefaultOpen }) => {
    /*
            Rendering top level section which contains sections
            */

    const [isOpen, setOpen] = useState(false);
    const [isHide, setIsHide] = useState(false)
    const { setSelectedSection, selectedSection, setRightbarLinks, selectedLink, setSelectedLink, setPreviewSelectedSection, isRequiredDataFilled, type, previewSelectedSections } = useContext(PageData)
    const divRef = useRef();

    useEffect(() => {
        if (selectedLink?.id === data?.id) {
            divRef.current.click()
            setSelectedLink({})
        } else if (selectedLink?.parentSectionID === data?.id) {
            setSelectedLink({})
            setOpen(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLink])

    useEffect(() => {
        if (isDefaultOpen && type !== CHECKOUT) {
            divRef.current.click()
        }

    }, [isDefaultOpen, type])

    useEffect(() => {
        if (isDefaultOpen && !selectedSection?.id) {
            setOpen(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDefaultOpen])

    useEffect(() => {
        if (selectedSection?.id === data?.id) {
            setOpen(selectedSection.isOpen)
            divRef.current.click()
            setIsHide(false)
            setSelectedSection()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const handleSectionClick = (e) => {
        Array.from(document.querySelectorAll('.selected-item-active')).forEach((el) => el.classList.remove('selected-item-active'));

        if (selectedSection?.id !== data?.id && previewSelectedSections.id === data.id) {
            setPreviewSelectedSection({ id: null, parentId: null })
            onClick()
        } else {
            e.target.closest(".accordion-title-outer").classList.add("selected-item-active");
            let links = makeRightbarLinks(data?.sections?.length > 0 ? data?.sections : [], data.id)
            setPreviewSelectedSection({ id: data.id, parentId: null })
            setRightbarLinks(links)

            if (isHide) {
                setIsHide(false)
            } else {
                if (selectedSection?.id) {
                    setOpen(selectedSection.isOpen)
                } else {
                    setOpen(true)
                }
            }
        }
    }

    const handleMouseEvent = (e) => {
        e.stopPropagation();
        setIsHide(true)
        setSelectedSection({ id: data?.id, isOpen: isOpen })
    }

    return (
        <ul key={extentionID} style={{ backgroundColor: isDragging ? 'white' : 'none', boxShadow: isDragging ? '2px 2px 5px lightgrey' : 'none' }}>
            <li className={data?.sections?.length > 0 ? `submenu-outer` : null}>
                <div
                    ref={divRef}
                    className={`accordion-title-outer ${isHideVisibility && 'title-spacing'} `}
                    data-component={data.sectionName}
                    data-location={location}
                    data-subcomponent={"main"}
                    data-top-section-id={data.id}
                    data-count={data.count}
                    data-mincount={data.minCount}
                    data-extention-id={extentionID}
                    data-target={target}
                    onClick={e => {
                        if (isRequiredDataFilled()) {
                            onClick(e);
                            handleSectionClick(e);
                        }
                    }}
                >
                    <div className="accordion-title-container" >
                        {
                            data?.sections?.length
                                ?
                                <div onClick={(e) => { e.stopPropagation(); setOpen(!isOpen) }} className={`accordion-dropdown-icon ${!isHide || isOpen ? "open" : ""}`}>
                                    <span className={!isHide && isOpen ? "accordion-dropdown-icon-open" : "accordion-dropdown-icon-close"}>
                                        {type === WIX ?
                                            <img src={CDN_BASE_URL + `assets/caret-down.svg`} alt={`plus icon`} />
                                            :
                                            <Icon source={CaretDownMinor} />
                                        }
                                    </span>
                                </div>
                                :
                                <div style={{ display: 'none' }}>
                                    <span>
                                        {type === WIX ?
                                            <img src={CDN_BASE_URL + `assets/caret-down.svg`} alt={`plus icon`} />
                                            :
                                            <Icon source={CaretDownMinor} />
                                        }
                                    </span>
                                </div>
                        }

                        <span
                            style={data?.sections?.length > 0 ? { paddingLeft: '0px' } : { paddingLeft: '10px' }}
                            className='accordion-title-span accordion-title'
                        >
                            {data.icon && <img src={CDN_BASE_URL + `assets/${data.icon}`} alt={`${data.sectionName} icon`} />}
                            {data.sectionName}
                        </span>
                    </div>
                    {!isHideVisibility && <VisibilityButtonGroup handleMouseEvent={handleMouseEvent} provided={provided} item={data} sectionID={data.id} topSectionID={data.id} extentionID={extentionID} target={target} onClick={onClick} />}
                </div>

                <div className={`${!isOpen || isHide ? "collapsed none" : "section-list"}`} >
                    <Section items={data.sections} isOpen={isOpen} target={target} options={data.addSectionOptions} isDefaultOpen={type === CHECKOUT ? isDefaultOpen : false} topSectionID={data.id} isHideAddSection={isHideAddSection} onClick={onClick} extentionID={extentionID} isHideVisibility={isHideVisibility} />
                </div>

            </li>
        </ul>

    )
}

const AddSectionButton = ({ item, onClick, id, location, pageType, renderPoint, style, handleAddSection, isRemovePadding }) => {
    /*
            Common add section button where added section and droppable place shows
            */

    const { dispatch, PrePurchaseConfigSetting, unifiedEditor, selectedPage, addSectionData, setAddSectionData, setIsHideLeftbarBanner, isHideLeftbarBanner, requiredData, setAddStaticUpsell, addStaticUpsell, type, isRequiredDataFilled, handleEditorChanges } = useContext(PageData)
    const [data, setData] = useState([])
    const [newAddedSection, setNewAddedSection] = useState({})
    const [isDragDisabled, setIsDragDisabled] = useState([])

    useEffect(() => {
        setIsDragDisabled(requiredData.stopChanges)
    }, [requiredData.stopChanges])

    useEffect(() => {
        const fetchData = async () => {
            if (PrePurchaseConfigSetting.length > 0) {
                const values = getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], item.target, '', '', '', id)
                if (values) {
                    const sections = await getSections(unifiedEditor.sections, values, item.target)
                    setData(sections)
                } else {
                    setData([])
                }
            }
        }
        fetchData()
    }, [PrePurchaseConfigSetting, id, item.target, unifiedEditor.sections, type])


    const handleChange = async (e) => {
        if (isRequiredDataFilled()) {
            let latest = _.cloneDeep(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
            const obj = getTargetedValue(unifiedEditor, e, '', '', '', id)
            let sections = getTargetedValue(latest, item.target, '', '', '', id)
            let values = _.cloneDeep(obj)
            values = assignIdsToBlocks(values)
            values.sectionID = Ids()
            if (values?.type) {
                if (values.type === 'group') {
                    values.sections = await getGroupData(unifiedEditor?.defaultSections, values.sections)

                }
                if (values.upsell_name) {
                    let newTarget = getTargetString(item.target, 2, 2)
                    let allSections = getTargetedValue(latest, newTarget, '', '', '', '')
                    values.upsell_name = await getUniqueUpsellName(values.upsell_name, allSections)
                };

                values = JSON.stringify(values).replaceAll("{{CDN_BASE_URL}}", CDN_BASE_URL)
                values = JSON.parse(values)

                if (sections) {
                    sections.push(values)
                } else {
                    let target = getTargetString(item.target, 2, 2)
                    sections = getTargetedValue(latest, target, '', '', '', id)
                    sections.push({ "extentionID": id, renderPoint: renderPoint, type: pageType ? pageType : selectedPage || 'Information', sections: [_.cloneDeep(values)] })
                }

                let editedData = handleEditorChanges('add', values.type === 'group' ? values.sections : [values])
               
                setNewAddedSection(values)
                setAddSectionData()
                dispatch({ type: UPDATE_STATE, payload: { ...latest, sectionAdded: true, editedData: editedData } })

                if (isHideLeftbarBanner) {
                    setIsHideLeftbarBanner(false)
                }

                if (handleAddSection) {
                    handleAddSection()
                }
            }
        }
    }

    // add section from popup when user first time open editor 
    useEffect(() => {
        if (addStaticUpsell && id === STATIC_EXTENTION_ID) {
            handleChange('defaultSections.[type:group]')
            setAddStaticUpsell(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addStaticUpsell])


    return (
        <>
            {/*  added section shows below  */}
            <Droppable droppableId={JSON.stringify({ ...item, renderPoint, id })} >
                {(provided) => (

                    <div id={`${id}`} className="droppable_zone active-sections" {...provided.droppableProps} ref={provided.innerRef} style={{ minHeight: '0px', marginLeft: isRemovePadding ? '0px' : '14px' }}>

                        {data.map((itemObj, index) => {
                            return <Draggable key={itemObj.id} draggableId={`${itemObj.id}`} index={index} isDragDisabled={isDragDisabled}>

                                {(provided, snapshot) => (
                                    <div className="section-container" ref={provided.innerRef} {...provided.draggableProps}>

                                        {itemObj?.sections
                                            ?
                                            <TopLevelSection
                                                provided={provided}
                                                isDefaultOpen={newAddedSection?.sectionID === itemObj?.id}
                                                isHideAddSection={!itemObj.addSectionOptions}
                                                data={itemObj}
                                                onClick={onClick}
                                                extentionID={id}
                                                location={location}
                                                target={itemObj.target}
                                                index={index}
                                                isDragging={snapshot.isDragging} />
                                            :
                                            (
                                                <Accordion
                                                    items={itemObj}
                                                    provided={provided}
                                                    isDefaultOpen={newAddedSection?.sectionID === itemObj?.id}
                                                    index={index}
                                                    onClick={onClick}
                                                    addblock={itemObj.blocks}
                                                    topSectionID={''}
                                                    extentionID={id}
                                                    location={location}
                                                    target={itemObj.target}
                                                    isDragging={snapshot.isDragging}
                                                />
                                            )}
                                    </div>
                                )}
                            </Draggable>
                        })}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>


            {/*  Droppable button  */}
            {item?.isVisible !== false && item && addSectionData &&

                <div className="add-section-button" style={style || {}}>
                    {type === WIX ?
                        <div onClick={() => handleChange(addSectionData.value)} className={`add-section-drop-btn add-section-padding active-button-container`}>

                            <img src={CDN_BASE_URL + `assets/plus-add-icon.svg`} alt={`plus icon`} />
                            {addSectionData.label}

                        </div>
                        :
                        <div className={`add-section-drop-btn add-section-padding active-button-container`}>
                            <Button onClick={() => handleChange(addSectionData.value)} icon={<Icon source={PlusMinor} />} >
                                {addSectionData.label}
                            </Button>
                        </div>
                    }
                </div>
            }

        </>
    )
}

const ListViewer = ({ data, onClick, buttonData, extentionID, target, isHideVisibility, provided, isDragging, isDraggingItem, isChild, handleOpenSection, isRemovePadding }) => {
    const { addSectionData, type } = useContext(PageData)
    const [isOpen, setOpen] = useState(data?.isAfterSection || false)
    const [isSectionOpen, setIsSectionOpen] = useState(false)

    const handleOpenClick = () => {
        setOpen(true);
    }

    useEffect(() => {
        if (!data?.isAfterSection && data?.sections?.length > 0) {
            let index = data?.sections.findIndex((item) => item.isAfterSection)
            if (index >= 0) {
                setOpen(true)
                setIsSectionOpen(true)
            }
        }
    }, [data?.isAfterSection, data?.sections])

    const checkIsopen = useCallback(() => {
        let value = false
        if (isOpen) {
            value = true
        }
        if ((data?.isAfterSection || isSectionOpen) && addSectionData) {
            value = true

        }
        return value
    }, [isSectionOpen, addSectionData, isOpen, data?.isAfterSection])

    const handleAddSection = () => {
        setOpen(true);
        if (handleOpenSection) {
            handleOpenSection()
        }
    }


    return (
        <ul key={extentionID} style={{ backgroundColor: isDragging ? 'white' : 'none', boxShadow: isDragging ? '2px 2px 5px lightgrey' : 'none' }}>
            <li className={data?.sections?.length > 0 ? `submenu-outer` : null}>
                <div
                    className={`accordion-title-outer ${isHideVisibility && 'title-spacing'} `}
                    data-component={data.sectionName}
                    data-subcomponent={"main"}
                    data-top-section-id={data.id}
                    data-extention-id={extentionID}
                    data-target={target}
                    onClick={e => {
                        handleOpenClick(e)
                    }}
                >
                    <div className="accordion-title-container" >
                        {
                            data?.sections?.length
                                ?
                                <div onClick={(e) => { e.stopPropagation(); setOpen(!isOpen) }} className={`accordion-dropdown-icon ${checkIsopen() ? "open" : ""}`}>
                                    <span className={checkIsopen() ? "accordion-dropdown-icon-open" : "accordion-dropdown-icon-close"}>
                                        {type === WIX ?
                                            <img src={CDN_BASE_URL + `assets/caret-down.svg`} alt={`plus icon`} />
                                            :
                                            <Icon source={CaretDownMinor} />
                                        }
                                    </span>
                                </div>
                                :
                                <div style={{ display: 'none' }}>
                                    <span>
                                        {type === WIX ?
                                            <img src={CDN_BASE_URL + `assets/caret-down.svg`} alt={`plus icon`} />
                                            :
                                            <Icon source={CaretDownMinor} />
                                        }
                                    </span>
                                </div>
                        }

                        <span
                            style={data?.sections?.length > 0 ? { paddingLeft: '0px', paddingTop: '6px' } : { paddingLeft: '5px' }}
                            className='accordion-title-span accordion-title'
                        >
                            {data.icon && <img src={CDN_BASE_URL + `assets/${data.icon}`} alt={`${data.sectionName} icon`} />}
                            {data.sectionName}
                        </span>
                    </div>
                    {!isHideVisibility && <VisibilityButtonGroup provided={provided} item={data} sectionID={data.id} topSectionID={data.id} extentionID={extentionID} target={target} onClick={onClick} />}
                </div>


                {data?.isAfterSection && (checkIsopen() || !data?.sections) &&
                    <div className="list-view" style={{ marginLeft: '15px' }}>

                        <AddSectionButton
                            item={buttonData}
                            onClick={onClick}
                            id={data.sectionId || data.id}
                            location={data.location}
                            handleAddSection={handleAddSection}
                            pageType={data.type}
                            renderPoint={data.sectionRenderPoint || data.renderPoint}
                            isVisible={isOpen || isDraggingItem || !data?.sections}
                            style={isChild ? { marginLeft: '-30px' } : { marginLeft: '-15px' }}
                            isRemovePadding={isRemovePadding}
                        />

                    </div>
                }

                {data.sections && checkIsopen() && data.sections.map((item, i) => {
                    return (
                        <div style={{ marginLeft: '15px' }} key={i}>
                            <ListViewer
                                isChild={true}
                                buttonData={buttonData}
                                isHideAddSection={true}
                                data={item}
                                onClick={onClick}
                                isHideVisibility={true}
                                index={i}
                                isDraggingItem={isDraggingItem}
                                handleOpenSection={handleAddSection}
                                isRemovePadding={true}
                            />
                        </div>
                    )
                })}
            </li>
        </ul>

    )

}

const SectionViewer = ({ data, onClick, extentionID, index, isDraggingItem, isRemovePadding }) => {
    /*
            Main section view
            */
    return (
        <ul key={index}>
            <li>
                {data.title &&
                    <div
                        className="accordion-title-outer"
                        data-component={data.sectionName}
                        data-subcomponent={"main"}
                        data-top-section-id={data.id}
                        data-extention-id={extentionID}
                    >
                        <div className="accordion-title-container title-spacing" >
                            <span
                                style={data?.sections?.length && { paddingLeft: '0px' }}
                                className="accordion-title-span accordion-title bold-text"
                            >
                                {data.title}
                            </span>
                        </div>
                    </div>
                }

                {data?.isShowButton &&
                    <AddSectionButton item={data.addSectionButton} isRemovePadding={data.title ? false : true} onClick={onClick} id={data.id} location={data.location} renderPoint={data.renderPoint} pageType={data.type} />
                }

                {data?.sections?.length > 0 &&
                    data.sections.map((item, i) => {
                        return (
                            <React.Fragment key={i}>
                                <ListViewer isRemovePadding={true} isHideAddSection={true} data={item} buttonData={data.addSectionButton} onClick={onClick} isHideVisibility={true} index={i} isDraggingItem={isDraggingItem} />


                                {item?.addSectionButton &&
                                    <div>
                                        <AddSectionButton item={data.addSectionButton} onClick={onClick} id={item.id} location={item.location} renderPoint={item.renderPoint} pageType={data.type} />
                                    </div>
                                }

                            </React.Fragment>
                        )
                    })

                }
            </li>
        </ul>
    )
}

const SectionContainer = ({ onClick, isDraggingItem }) => {

    const { unifiedEditor, PrePurchaseConfigSetting, selectedPage } = useContext(PageData)
    const [data, setData] = useState()

    useEffect(() => {
        if (unifiedEditor.pages?.length === 1) {
            setData(unifiedEditor.pages[0].value)
        } else {
            let index = unifiedEditor.pages?.findIndex((i) => i.type === selectedPage.toLowerCase())
            if (index >= 0) {
                setData(unifiedEditor.pages[index].value)
            }
        }
    }, [unifiedEditor, PrePurchaseConfigSetting, selectedPage])

    return (
        data === undefined ? null : (
            data.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        <SectionViewer data={item} onClick={onClick} index={index} isDraggingItem={isDraggingItem} />
                    </React.Fragment>
                );
            })
        )

    );
}


const LeftBar = props => {
    const { unifiedEditor, PrePurchaseConfigSetting, dispatch, selectedPage, type, loading, setAddSectionData, isHideLeftbarBanner, isBannerEnable, isRequiredDataFilled } = useContext(PageData)
    const [isDraggingItem, setIsDraggingItem] = useState(false);
    const [data, setData] = useState(unifiedEditor)
    // const flags = useFlags();
    // const isBannerEnable = flags[LAUNCH_DARKLY_ENABLE_BANNER_KEY];
    // const ldClient = useLDClient();
    const { addSectionOptions } = unifiedEditor

    // useEffect(() => {
    //     ldClient && ldClient.identify({ key: STATIC_SHOP });
    // }, [ldClient]);

    console.log("isBannerEnable", isBannerEnable)

    useEffect(() => {
        setData(unifiedEditor)
    }, [unifiedEditor])

    const handleBeforeCapture = () => {
        setIsDraggingItem(true);

        const droppables = document.querySelectorAll('.droppable_zone');
        droppables.forEach(droppable => {
            droppable.style.border = '1px dashed lightgrey';
            droppable.style.borderRadius = '0.25rem';
            droppable.style.minHeight = "10px";
            droppable.style.marginBottom = '5px'
        });
    }

    const handleDragUpdate = (update) => {
        const { destination } = update;

        const droppables = document.querySelectorAll('.droppable_zone');
        droppables.forEach(droppable => {
            droppable.style.border = '1px dashed lightgrey';
            droppable.style.backgroundColor = 'white';
        });

        if (!destination) {
            return;
        }

        const droppable = document.getElementById(JSON.parse(destination.droppableId).id);
        droppable.style.border = '1px dashed rgba(69, 143, 255, 1)';
        droppable.style.backgroundColor = 'rgba(237, 244, 254, 1)';
    }

    const handleDragEnd = async (result) => {
        setIsDraggingItem(false);
        const droppables = document.querySelectorAll('.droppable_zone');
        droppables.forEach(droppable => {
            droppable.style.border = '0';
            droppable.style.borderRadius = '0';
            droppable.style.minHeight = "0px";
            droppable.style.marginBottom = '0px'
            droppable.style.backgroundColor = 'white';
        });

        if (!result.destination) return;
        let dropIndex = result.destination.index
        let sourceIndex = result.source.index
        result.destination = JSON.parse(result.destination.droppableId)
        result.source = JSON.parse(result.source.droppableId)
        let target = result.source.target

        let latestData = _.cloneDeep(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])

        let arr = getTargetedValue(latestData, target, "", "", "", result.source.id)
        let newObj = { ...arr[sourceIndex] }
        arr.splice(sourceIndex, 1);
        let newArr = getTargetedValue(latestData, target, "", "", "", result.destination.id)

        if (newArr) {
            newArr.splice(dropIndex, 0, newObj)
        } else {
            target = getTargetString(target, 2, 2)
            let sections = getTargetedValue(latestData, target, '', '', '', '')
            sections.push({ "extentionID": result.destination.id, renderPoint: result.destination.renderPoint, sections: [_.cloneDeep(newObj)], type: selectedPage || 'Information' })
        }
        dispatch({ type: UPDATE_STATE, payload: latestData });
    }

    const handleAddSection = (v) => {
        if (isRequiredDataFilled()) {
            let data = unifiedEditor.addSectionOptions
            let index = data.findIndex((i) => i.value === v)
            if (index >= 0) {
                let obj = data[index]
                setAddSectionData(obj)
            }
        }
    }

    const sectionOptions = useCallback(() => {
        if (addSectionOptions) {
            if (type === CHECKOUT && !isBannerEnable) {
                return addSectionOptions.filter((x) => x.label !== BANNER_LABEL)
            }
            return addSectionOptions
        }
        return []
    }, [addSectionOptions, isBannerEnable, type])

    return (
        <div className={`left-section ${type === CHECKOUT ? 'ce-leftbar' : ''}`}>
            {type === PREPURCHASE &&
                <>
                    <PreviewSection />
                    <Divider rootStyles={{ height: '0px' }} />
                </>
            }

            {type === CHECKOUT &&
                <div>
                    <h4 className="leftbar_page_heading">{`${selectedPage ? selectedPage : 'Information'} page`}</h4>
                </div>
            }

            <div className="content_sidebar">
                <div className="sidebar-menu-box ">
                    {type === CHECKOUT &&
                        <>
                            <div>
                                <h6 className="leftbar_header">Header</h6>
                                <div className="leftbar-divider" />
                            </div>
                        </>
                    }

                    {loading ?
                        <LeftbarSkeleton />
                        :
                        <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate} onBeforeCapture={handleBeforeCapture}>
                            <SectionContainer onClick={props.onClick} isDraggingItem={isDraggingItem} />
                        </DragDropContext>
                    }

                    {type === CHECKOUT &&
                        <>
                            <Divider rootStyles={{ marginRight: '16px' }} />

                            <div>
                                <h6 className="leftbar_footer">Footer</h6>
                            </div>
                        </>
                    }
                </div>

            </div>

            {!isHideLeftbarBanner && type === CHECKOUT &&
                <div className="leftbar-banner-wrap">
                    <WarningBanner />
                </div>
            }
            <ShopifyReviewLeftbarWidget />

            <div className="leftbar-add-section">
                {type === WIX ?
                    <AddSectionDropdown
                        title={'Add section'}
                        optionTitle={'ADD SECTION'}
                        options={sectionOptions() || []}
                        handleChange={handleAddSection}
                    />
                    :
                    <PopoverWithSearchbox
                        options={sectionOptions() || []}
                        isHideSearchbar={true}
                        isAddSection={true}
                        optionTitle={'ADD SECTION'}
                        handleChange={handleAddSection}
                        iconSource={CirclePlusMinor}
                        title={'Add section'}
                        activatorClass={'add-section-activator'}
                    />

                }
            </div>

            {data?.globalSettings &&
                <div className="left-bottom_container">
                    {data.globalSettings.map((item, index) => {

                        return (

                            <div key={index} className="offerSetting_sub_sections fontStyle" data-component={'Settings'} data-subcomponent="" onClick={props.onClick}>
                                {item.label}
                            </div>

                        )
                    })}
                </div>
            }
        </div >
    );
}

export default LeftBar;
