import PropTypes from 'prop-types';
import { Button as PolarisButton } from "@shopify/polaris";
import styles from './Button.module.css'
import { WIX } from '../../../Constant';
import { useContext } from 'react';
import { PageData } from '../../../ContextAPI/context';
import { Button as ChakraButton } from '@chakra-ui/react';

const Button = (props) => {
    const { type } = useContext(PageData)
    return (
        <>
            {type === WIX ?
                <div className={styles.preview_button}>
                    <ChakraButton className={styles.chakra_btn} colorScheme='gray' variant='outline' onClick={props.onClick}>
                        {props.text}
                    </ChakraButton>
                </div>
                :
                <div className={styles.preview_button}>
                    <PolarisButton {...props}>
                        {props.text}
                    </PolarisButton>
                </div>
            }
        </>
    )
}

Button.prototype = {
    text: PropTypes.string,
    primary: PropTypes.func
}


export default Button