import { Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';

function LeftbarSkeleton() {
    const arr = Array.from(Array(10).keys())

    return (
        <div className='skeleton-wrapper'>

            {arr.map((item, index) => {
                return (
                    <>
                        <Stack className='skeleton-main-block' spacing={3} direction='row' align='center'>
                            <Skeleton height='22px' width='24px' /> <Skeleton className='skeleton-details' height='20px' />
                        </Stack>
                        {index % 3 === 0 &&
                            <div className='skeleton-sub-block'>
                                <Stack className='skeleton-main-block' spacing={3} direction='row' align='center'>
                                    <Skeleton height='22px' width='24px' /> <Skeleton className='skeleton-details' height='20px' />
                                </Stack>
                                <Stack className='skeleton-main-block' spacing={3} direction='row' align='center'>
                                    <Skeleton height='22px' width='24px' /> <Skeleton className='skeleton-details' height='20px' />
                                </Stack>
                            </div>

                        }
                    </>
                )
            })}

        </div>
    );
}


export default LeftbarSkeleton;