import {
    AspectRatio,
    Box,
    Container,
    Input,
    Stack,
    Text
} from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import styles from './ChakraImageUpload.module.css'

export default function ChakraImageUpload({ onDrop }) {
    const controls = useAnimation();
    const startAnimation = () => controls.start("hover");
    const stopAnimation = () => controls.stop();
    return (
        <Container className={styles.image_container} >
            <AspectRatio width="64" ratio={1} className={styles.group_container}>
                <Box
                    borderColor="gray.300"
                    borderStyle="dashed"
                    borderWidth="1px"
                    rounded="md"
                    shadow="sm"
                    role="group"
                    transition="all 150ms ease-in-out"
                    _hover={{
                        borderColor: "blue.700"
                    }}
                    as={motion.div}
                    initial="rest"
                    animate="rest"
                    whileHover="hover"
                >
                    <Box position="relative" height="120px" width="120px">
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            height="120px"
                            width="120px"
                            display="flex"
                            flexDirection="column"
                        >
                            <Stack
                                height="120px"
                                width="120px"
                                display="flex"
                                alignItems="center"
                                justify="center"
                                spacing="4"
                            >
                                <Stack p="8" textAlign="center" spacing="1">
                                   
                                    <Text className={styles.upload_button} fontWeight="light">Upload</Text>
                                </Stack>
                            </Stack>
                        </Box>
                        <Input
                            type="file"
                            height="100%"
                            width="100%"
                            position="absolute"
                            top="0"
                            left="0"
                            cursor="pointer"
                            opacity="0"
                            aria-hidden="true"
                            accept="image/*"
                            onDragEnter={startAnimation}
                            onDragLeave={stopAnimation}
                            multiple={true}
                            onChange={(e) => { onDrop(e?.target?.files); }}
                        />
                    </Box>
                </Box>
            </AspectRatio>
        </Container>
    );
}
