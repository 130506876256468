import { Banner, List, Link } from '@shopify/polaris';
import React, { useContext, useEffect, useState } from 'react';
import { CHECKOUT_HELP_MODAL_DATA, SHIOPIFY_CHECKOUT_LINK } from '../../Constant';
import { PageData } from '../../ContextAPI/context';

const WarningBanner = () => {
    const { shop, setHelpModalData } = useContext(PageData)
    const [link, setLink] = useState('')

    useEffect(() => {
        if (shop) {
            let link = SHIOPIFY_CHECKOUT_LINK
            setLink(link.replace('{SHOP_NAME}', shop))
        }
    }, [shop])

    const handleClick = () => {
        setHelpModalData(CHECKOUT_HELP_MODAL_DATA)
    }

    return (
        <>


            <Banner
                title=""
                action={{ content: 'Go to Shopify Checkout Editor', url: link, external: true }}
                status="warning"

            >
                <List>
                    <List.Item>
                    You need to add ReConvert as an app in your Shopify checkout editor. Checkout customizations can’t be displayed until you do this. Ignore this message if you've already done this.

                        <p className='warning-banner-link'> <Link url="" onClick={()=>handleClick(true)}>Help Tutorial</Link></p>
                    </List.Item>
                </List>
            </Banner>
        </>
    );
}

export default WarningBanner