import { Toast as Notification } from '@shopify/polaris';


const Toast = ({content, active, toggleActive, error}) => {

    if (!active) {
        return null
    }

    return (
        <Notification content={content} error={error} onDismiss={toggleActive} />
    );
}

export default Toast