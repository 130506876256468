import PropTypes from 'prop-types';
import { RangeSlider as PolarisRangeSlider } from "@shopify/polaris";

function Slider(props) {
    return (
        <div className={props.className ? props.className : "py-2 mt-3 mb-3"}>
            <div className="ue__icon_set">
                <PolarisRangeSlider
                    {...props}
                    label={props.label}
                    value={props.value || 0}
                    onChange={props.onChange}
                    output
                />
            </div>
        </div>
    );
}

Slider.prototype = {
    label: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    prefix: PropTypes.string,
    suffix: PropTypes.string
}

export default Slider