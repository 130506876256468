import { REDO_STATE, REPLACE_STATE, UNDO_STATE, UPDATE_STATE } from "../../Constant";

const Reducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    case UPDATE_STATE:
      return [...state, payload];
    case REPLACE_STATE:
      return payload;
    case UNDO_STATE:
      return payload;
    case REDO_STATE:
      return [...state, payload];
    default:
      return state;
  }
};

export default Reducer;
