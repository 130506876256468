import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react'
import React from 'react'


function WixModal({ active, handleClose, handleSubmit, primaryBtnLabel, secondaryBtnLabel, title, content }) {

    return (
        <>
            <Modal onClose={handleClose} isOpen={active} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {content}
                    </ModalBody>
                    <ModalFooter>
                        <Stack spacing={3} direction='row' align='center'>
                            <Button colorScheme='gray' variant='outline' onClick={handleClose}>{secondaryBtnLabel}</Button>
                            <Button colorScheme='teal' variant='solid' onClick={handleSubmit}>{primaryBtnLabel}</Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default WixModal
