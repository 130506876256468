import { Button as ShopifyButton, ButtonGroup, Icon, TextField, Spinner, Banner } from '@shopify/polaris';
import { SearchMinor, } from '@shopify/polaris-icons';
import React, { useState, useCallback, useContext, useEffect } from 'react';
import { CDN_BASE_URL } from '../../config';
import { getTargetedValue, CreatedNewObj, updateTargetedValue, getTargetString } from '../../Actions/common';
import { PageData } from '../../ContextAPI/context';
import { FilterStates } from "../SelectorArea"
import { fetchShopifyProduct, fetchShopifyProductsWithSearch } from '../../Service/index';
import SectionHeader from './SectionHeader';
import { PRODUCT_UPSELL, UPDATE_STATE, WIX} from '../../Constant';
import Divider from '../Divider';
import CustomTooltip from '../commonComp/CustomTooltip';
import styles from './ProductSelector.module.css'
import { Button, Input, InputGroup, InputLeftElement, Spinner as ChakraSpinner } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

const LIMIT = 15;

const SelectProduct = (props) => {

    const { data: product, sectionID, topSectionID, extentionID, target, main, obj } = props.data

    const { filterState } = useContext(FilterStates)
    const { PrePurchaseConfigSetting, dispatch, type, isMicroService, handleEditorChanges } = useContext(PageData)

    const [searchValue, setSearchValue] = useState('');
    const [products, setProducts] = useState([]);
    const [endCursor, setEndCursor] = useState();
    const [hasNextPage, setHasNextPage] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(product || {})
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [error, setError] = useState('');

    const close = useCallback(() => {
        filterState({
            status: false,
            component: ''
        });

        document.querySelector(".right-section").classList.add('overflow_vertical_scroll');
        document.querySelector(".right-section-title")?.classList?.remove("removeDiv")
        document.querySelector(".bottom_container")?.classList?.remove("removeDiv")
        document.querySelector(".links-container")?.classList?.remove("removeDiv")
    }, [filterState]);

    const SearchData = (searchTerm) => {
        setSearchValue(searchTerm);
        setProducts([]);
        searchProducts(searchTerm);
    }

    const selectProduct = (id) => {
        const selectedProduct = products.find(x => x.id === id)
        setSelectedProduct(selectedProduct);
    }

    const Productselect = async () => {
        const data1 = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
        let newTarget = target
        let index = newTarget.lastIndexOf(']');
        let sectionTarget = ''
        if (index >= 0) {
            sectionTarget = newTarget.slice(0, index + 1)
        }

        const section = getTargetedValue(data1, sectionTarget, '', sectionID, topSectionID, extentionID);




        // get product id which is the part after last / in selectedProduct.id field
        const productId = selectedProduct.id.substr(selectedProduct.id.lastIndexOf("/") + 1);
        const productRes = await fetchShopifyProduct(productId, isMicroService)

        let dataValue = await updateTargetedValue(data1, target, productRes.data.product, "", sectionID, topSectionID, extentionID)
        if (section.blocks) {
            const imageBlockId = section.blocks.find(block => block.type === section.sectionSettings.imageBlockName).id;
            dataValue = await updateTargetedValue(dataValue, `${sectionTarget}.${section.sectionSettings.imageFitTarget}`, 'contain', imageBlockId, sectionID, topSectionID, extentionID)

        }
        if (obj.updateConditions) {
            // update imageQty for image block
            let blockTarget = target
            blockTarget = getTargetString(blockTarget, 3, 3)
            let conditionObj = obj.updateConditions
            let targeted = `${blockTarget}.${conditionObj.targetBlock}`
            let block = getTargetedValue(data1, targeted, '', sectionID, topSectionID, extentionID)

            if (block[conditionObj.conditions.target] !== conditionObj.conditions.value) {
                dataValue = await updateTargetedValue(dataValue, `${targeted}.${conditionObj.target}`, productRes.data.product?.images?.length, "", sectionID, topSectionID, extentionID)
            }
        }
        let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
        dispatch({ type: UPDATE_STATE, payload: {...dataValue, editedData} })
        filterState({ status: false, component: '' })

        document.querySelector(".right-section-title")?.classList?.remove("removeDiv")
        document.querySelector(".bottom_container")?.classList?.remove("removeDiv")
        document.querySelector(".links-container")?.classList?.remove("removeDiv")
    }

    const unselectProduct = () => {
        setSelectedProduct({})
        filterState({ status: false, component: '' })
        document.querySelector(".right-section-title")?.classList?.remove("removeDiv")
        document.querySelector(".bottom_container")?.classList?.remove("removeDiv")
        document.querySelector(".links-container")?.classList?.remove("removeDiv")
    }

    const loadMoreProducts = async () => {
        try {
            setError("");
            setLoadMoreLoading(true);

            const response = await fetchShopifyProductsWithSearch(searchValue, endCursor, LIMIT, isMicroService);

            const fetchedProducts = response.data.products.map(product => ({
                ...product,
                image: product.images && product.images.length ? product.images[0] : null
            }));

            const data = response.data;
            setProducts((prevProducts) => [...prevProducts, ...fetchedProducts]);
            setEndCursor(data?.pageInfo?.endCursor);
            setHasNextPage(data?.pageInfo?.hasNextPage);
        } catch (error) {
            setError("Failed to fetch products");
        } finally {
            setLoadMoreLoading(false);
        }
    }

    const searchProducts = useCallback(async (q) => {
        try {
            setError("");
            setLoadingProducts(true);

            const response = await fetchShopifyProductsWithSearch(q, null, LIMIT, isMicroService);
            const fetchedProducts = response.data.products.map(product => ({
                ...product,
                image: product.images && product.images.length ? product.images[0] : null
            }));

            const data = response.data;
            setProducts(fetchedProducts);
            setEndCursor(data?.pageInfo?.endCursor);
            setHasNextPage(data?.pageInfo?.hasNextPage);
        } catch (error) {
            setError("Failed to fetch products");
        } finally {
            setLoadingProducts(false);
        }
    }, [isMicroService]);

    useEffect(() => {
        return () => close()
    }, [main, close])

    useEffect(() => {
        searchProducts("");
    }, [searchProducts]);

    const productList =
        products
            .map(({ id, image, title }) => (
                <div key={id} className={`product_item_container ${id === selectedProduct.id ? "selected_item" : ""}`}>
                    <li className={`product_item ${id === selectedProduct.id ? "selected" : ""}`} onClick={() => selectProduct(id)}>
                        <div className={`product_image ${styles.image_wrap}`} >
                            <img
                                src={image ? image : CDN_BASE_URL + 'assets/dummy_small.png'}
                                alt={selectedProduct?.title}
                                className={styles.selected_image}
                            />
                        </div>

                        <div className="product-title">
                            <span>{title}</span>
                        </div>
                    </li>
                </div>
            ));


    return (
        <div className='product_selector_container'>

            <SectionHeader title="Select Featured product" onClose={close} />

            <Divider />

            <div className='product_top_section'>
                <div className='product_search m-3'>
                    {type === WIX ?
                        <InputGroup className={styles.input_grop_wrap}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input type='text' placeholder='Filter' value={searchValue} onChange={(e) => SearchData(e.target.value)} />
                        </InputGroup>
                        :
                        <TextField
                            prefix={<Icon source={SearchMinor} color="base" />}
                            placeholder="Filter"
                            onChange={SearchData}
                            value={searchValue}
                            autoComplete='off'
                        />
                    }
                </div>

                <div className='specific_product_list'>
                    <ul>
                        {error && <div className='text-center'>{error}</div>}
                        {!error && loadingProducts && <div className='text-center'>{type === WIX ? <ChakraSpinner /> : <Spinner accessibilityLabel="Loading products" size="large" />}</div>}
                        {!error && !loadingProducts && products.length === 0 && <div className="text-center">No match found</div>}
                        {!error && !loadingProducts && products.length > 0 && productList}

                        {!error && !loadingProducts && hasNextPage &&
                            <div className='load_more_button_container'>
                                {type === WIX ?
                                    <Button className={styles.load_more_btn} colorScheme='gray' variant='outline' disabled={loadMoreLoading} fullWidth onClick={() => loadMoreProducts()}>Load more</Button>
                                    :
                                    <ShopifyButton disabled={loadMoreLoading} fullWidth onClick={() => loadMoreProducts()}>Load more</ShopifyButton>
                                }
                            </div>
                        }
                    </ul>
                </div>
            </div>


            <div className='product_bottom_section'>
                {selectedProduct?.id &&
                    <div className='selected_product_container'>
                        <div className={`selected_product_image ${styles.image_wrap}`}>
                            <img
                                src={selectedProduct?.image?.src ? selectedProduct?.image?.src : selectedProduct?.image ? selectedProduct?.image : CDN_BASE_URL + 'assets/dummy_small.png'}
                                alt={selectedProduct?.title}
                                className={styles.selected_image}
                            />
                        </div>
                        <div className='product-title'>
                            <span className='product_subtitle'>Currently selected</span><br />
                            <span>{selectedProduct.title || selectedProduct.name}</span>
                        </div>
                    </div>
                }

                <div className='product_selector_controls'>
                    {type === WIX ?
                        <>
                            <Button colorScheme='gray' variant='outline' onClick={unselectProduct}>Cancel</Button>
                            <Button className={styles.main_button} colorScheme='teal' variant='solid' disabled={!selectedProduct?.id} onClick={Productselect}>Select</Button>
                        </>
                        :
                        <ButtonGroup>
                            <ShopifyButton onClick={unselectProduct}>Cancel</ShopifyButton>
                            <ShopifyButton disabled={!selectedProduct?.id} primary onClick={Productselect}>Select</ShopifyButton>
                        </ButtonGroup>
                    }
                </div>
            </div>
        </div>
    )
}

const ProductSelectedData = (props) => {
    const { data, target, sectionID, topSectionID, extentionID } = props
    const { PrePurchaseConfigSetting, dispatch, unifiedEditor, type, handleEditorChanges } = useContext(PageData)
    const { filterState } = useContext(FilterStates)

    const changeProduct = () => {
        filterState({
            status: true,
            component: <SelectProduct data={{ ...props }} />
        })

        document.querySelector(".right-section").classList.remove('overflow_vertical_scroll');
        document.querySelector(".right-section-title")?.classList?.add("removeDiv")
        document.querySelector(".bottom_container")?.classList?.add("removeDiv")
        document.querySelector(".links-container")?.classList?.add("removeDiv")
    }

    const deleteProduct = async () => {
        const latestPrepurchaseSettings = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);

        let defaultSectionTarget = `defaultSections.[type:product_upsell].sectionSettings.productData`
        let defaultProductData = getTargetedValue(unifiedEditor, defaultSectionTarget)
        defaultProductData = JSON.stringify(defaultProductData).replaceAll("{{CDN_BASE_URL}}", CDN_BASE_URL)
        defaultProductData = JSON.parse(defaultProductData)

        const updatedPrepurchaseSettings = await updateTargetedValue(latestPrepurchaseSettings, target, defaultProductData, null, sectionID, topSectionID, extentionID);
        let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
        dispatch({ type: UPDATE_STATE, payload: {...updatedPrepurchaseSettings, editedData} });
    }

    return (
        <>
            <div className='flex-list'>
                <div className='product-image'>
                    {type === WIX ?
                        <img src={data?.media?.mainMedia?.image?.url ? data.media.mainMedia.image.url : CDN_BASE_URL + 'assets/dummy_small.png'} alt={'Product'} />
                        :
                        <img src={data?.image?.src ? data.image.src : CDN_BASE_URL + 'assets/dummy_small.png'} alt={'Product'} />
                    }
                </div>
                <div className='product-title'>
                    <span >{data.title || data.name}</span>
                </div>
            </div>
            {type === WIX ?
                <div className={`flex-list ${styles.button_groups}`} >
                    <div >
                        <Button colorScheme='gray' variant='outline' onClick={changeProduct}>
                            Change
                        </Button>
                    </div>
                    <div className={styles.delete_btn}>
                        <Button colorScheme='gray' variant='outline' onClick={deleteProduct}>
                            Delete
                        </Button>
                    </div>
                </div>
                :
                <div className='flex-list m-3' style={{ justifyContent: 'center' }}>
                    <div >
                        <ShopifyButton onClick={changeProduct}>
                            Change
                        </ShopifyButton>
                    </div>
                    <div className='product-title'>
                        <ShopifyButton onClick={deleteProduct}>
                            Delete
                        </ShopifyButton>
                    </div>
                </div>
            }
        </>
    )
}

const ProductSelector = (props) => {
    const { obj, sectionID, topSectionID, extentionID, target } = props.data
    const { filterIsOpen, filterState } = useContext(FilterStates)
    const { PrePurchaseConfigSetting, type, setRequiredData, requiredData } = useContext(PageData)
    const [productData, setProductData] = useState();

    useEffect(() => {
        let productData = getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], target, '', sectionID, topSectionID, extentionID)
        setProductData(productData);
        if ((!productData || productData === " " || productData.isDefault) && obj.isRequired) {
            setRequiredData({ ...requiredData, stopChanges: true })
        }

        return () => {
            setRequiredData({ stopChanges: false, showToast: false, showErrorMessage: false })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PrePurchaseConfigSetting, target, extentionID, sectionID, topSectionID])

    const changeFont = () => {
        document.querySelector(".right-section-title")?.classList?.add("removeDiv")
        document.querySelector(".bottom_container")?.classList?.add("removeDiv")
        document.querySelector(".links-container")?.classList?.add("removeDiv")
        filterState({
            status: true,
            component: <SelectProduct data={{ ...props.data }} />
        });
    }

    return (
        <div className={styles.selector_wrap}>
            {filterIsOpen === true ? null : <div className="component-spacing">
                <CustomTooltip label={obj.label} text={"Product"} />
                <div className='card'>
                    <div>
                        {
                            !productData || productData === " " || productData.isDefault ?
                                <div className={styles.button_container} >
                                    <div>
                                        {type === WIX ?
                                            <Button className={styles.button_wrap} colorScheme='gray' onClick={changeFont} variant='outline'>
                                                {obj.label}
                                            </Button>
                                            :
                                            <ShopifyButton
                                                onClick={changeFont}
                                                fullWidth
                                            >
                                                {obj.label}
                                            </ShopifyButton>
                                        }
                                    </div>
                                </div>
                                :
                                <ProductSelectedData data={productData} obj={obj} target={target} sectionID={sectionID} topSectionID={topSectionID} extentionID={extentionID} />
                        }
                    </div>
                </div>
                {requiredData.showErrorMessage && (!productData || productData === " " || productData.isDefault) && obj.isRequired &&
                    <div className='product-selector-banner'>
                        <Banner status="critical" >
                            You need to select a product
                        </Banner>
                    </div>
                }
            </div>}
        </div>
    )
}

export default ProductSelector

