//params keys
export const PAGE = "page";
export const TYPE = "type";
export const REDIRECT_URL = "redirectUrl";
export const SHOP = "shop"
export const FV = 'fv'
export const HMAC_AUTH_TOKEN = 'app_hmac_auth'

//section type
export const BANNER = 'banner'
export const TITLE = 'title'
export const BANNER_LABEL = 'Banner'
export const PRODUCT_UPSELL = 'product_upsell'
export const TEXT = 'text'

//action button type
export const CUSTOMIZATIONS = 'Customizations'
export const BRANDING = 'Branding'

//localstorge keys
export const AUTH_TOKEN = "authToken";

//redux case
export const UPDATE_STATE = "UPDATE_STATE";
export const REPLACE_STATE = "REPLACE_STATE";
export const UNDO_STATE = "UNDO_STATE";
export const REDO_STATE = "REDO_STATE";

export const REACT_APP_TITLE = "Checkout customizer • ReConvert";

export const BACK = "Back"

// unified types

export const CHECKOUT = "CHECKOUT"
export const PREPURCHASE = "PREPURCHASE"
export const WIX = 'WIX'
export const POSTPURCHASE = 'POST_PURCHASE'

// right banner pagetype by unified type

export const pageTypeByUnifiedType = {
    PREPURCHASE: 'pre-puchase page',
    WIX: 'checkout page'
}

//const static id for leftbar location
export const STATIC_EXTENTION_ID = 3333

//link 

export const SHOPIFY_SEARCH_DISCOVERY_APP_LINK ='https://apps.shopify.com/search-and-discovery'

export const SHIOPIFY_CHECKOUT_LINK = 'https://{SHOP_NAME}/admin/settings/checkout/editor'

export const CHECKOUT_HELP_MODAL_DATA = {
    title: 'How to add the ReConvert checkout sections and upsells to your Shopify checkout',
    link: 'https://help.stilyoapps.com/en/article/how-to-add-the-reconvert-checkout-sections-and-upsells-to-your-shopify-checkout-1u220q2/reader/?bust=1666165481789'
}

export const SECTION_HELP_MODAL_DATA = {
    Divider: {
        title: 'Divider section',
        link: 'https://help.stilyoapps.com/en/article/divider-section-y32pzg/reader/?bust=1666165841598'
    },
    Heading: {
        title: 'Heading section',
        link: 'https://help.stilyoapps.com/en/article/heading-checkout-section-1m6vlb9/reader/'
    },
    Text: {
        title: 'Text section',
        link: 'https://help.stilyoapps.com/en/article/heading-checkout-section-1m6vlb9/reader/'
    },
    Banner: {
        title: 'Banner section',
        link: 'https://help.stilyoapps.com/en/article/heading-checkout-section-1m6vlb9/reader/'
    },
    "Product upsell": {
        title: 'Single product upsell checkout section',
        link: 'https://help.stilyoapps.com/en/article/single-product-upsell-checkout-section-1xwtjil/reader/?bust=1666166184888'
    },
    "Multi-product upsell": {
        title: 'Multi product checkout upsell section',
        link: 'https://help.stilyoapps.com/en/article/multi-product-checkout-upsell-section-1l01pdo/reader/?bust=1666165907126'
    },
    Image: {
        title: 'Image block',
        link: 'https://help.stilyoapps.com/en/article/reconvert-checkout-blocks-1u3g4ni/reader/?bust=1666166220499#6-image-block'
    },
    "Product Details": {
        title: 'Product details block',
        link: 'https://help.stilyoapps.com/en/article/reconvert-checkout-blocks-1u3g4ni/reader/?bust=1666166220499#6-product-details-block'
    },
    Description: {
        title: 'Description block',
        link: 'https://help.stilyoapps.com/en/article/reconvert-checkout-blocks-1u3g4ni/reader/?bust=1666166220499#6-description-block'
    },
    "Price and discount": {
        title: 'Price and discount block',
        link: 'https://help.stilyoapps.com/en/article/reconvert-checkout-blocks-1u3g4ni/reader/?bust=1666166220499#6-price-and-discount-block'
    },
    Button: {
        title: 'Button block',
        link: 'https://help.stilyoapps.com/en/article/reconvert-checkout-blocks-1u3g4ni/reader/?bust=1666166220499#6-button-block'
    },
    "Variant Picker": {
        title: 'Variant picker block',
        link: 'https://help.stilyoapps.com/en/article/reconvert-checkout-blocks-1u3g4ni/reader/?bust=1666166220499#6-variant-picker-block'
    },
    "Quantity Selector": {
        title: 'Quantity picker block',
        link: 'https://help.stilyoapps.com/en/article/reconvert-checkout-blocks-1u3g4ni/reader/?bust=1666166220499#6-quantity-picker-block'
    },
    "Success/Error banner": {
        title: 'Success/Error banner',
        link: 'https://help.stilyoapps.com/en/article/reconvert-checkout-blocks-1u3g4ni/reader/?bust=1666166220499#6-successerror-banner'
    },
    "Recommendation intent":{
        title: 'Recommendation intent',
        link: 'https://help.stilyoapps.com/en/article/reconvert-checkout-blocks-1u3g4ni/reader/?bust=1666166220499#6-successerror-banner'
    }
}


export const SECTION_LOCATION_HELP_MODAL_DATA = {
    title: 'What are dynamic locations for checkout upsells?',
    link: 'https://help.reconvert.io/en/article/what-are-dynamic-locations-for-checkout-upsells-10sfxfz'
}

export const HELP_VIDEO_DATA = {
    title: 'How to setup ReConvert Checkout upsell using Shopify checkout extensions',
    link: 'https://help.stilyoapps.com/en/article/how-to-setup-reconvert-checkout-upsell-using-shopify-checkout-extensions-shopify-plus-only-1cp01uq/reader/?bust=1664718737927'
}

export const FIRST_TIME_USER_POPUP_DATA = {
    title: 'How to setup ReConvert Checkout upsell using Shopify checkout extensions',
    link: 'https://help.stilyoapps.com/en/article/how-to-setup-reconvert-checkout-upsell-using-shopify-checkout-extensions-shopify-plus-only-1cp01uq/reader/?bust=1665133023516'
}