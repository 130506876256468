import PropTypes from 'prop-types';
import FroalaEditor from 'react-froala-wysiwyg';
import CustomTooltip from '../CustomTooltip';
import 'froala-editor/js/plugins.pkgd.min.js';
import Froalaeditor from 'froala-editor';
import { useCallback, useEffect, useState } from 'react';
import styles from './TextEditor.module.css'

export const TextEditor = (props) => {
    const { label, tooltipText, defaultText, onChange, showTooltip } = props
    const [value, setValue] = useState(props.value)

    const handleChange = useCallback((e) => {
        console.warn('test',e);
        setValue(e)
        onChange(e)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange])

    useEffect(() => {
        setValue(props.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value])

    Froalaeditor.DefineIcon("textSize", {NAME: 'fontSize', SVG_KEY: 'fontSize'})
    Froalaeditor.RegisterCommand('textSizeCustom', {
      title: 'Font Size',
      icon: 'textSize',
      type: 'dropdown',
        options: {
            '10px': 'Extra Small',
            '12px': 'Small',
            '14px': 'Base',
            '16px': 'Medium',
            '18px': 'Large',
            '24px': 'Extra Large'
        },
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function (cmd, val) {
        console.log (val);
        this.fontSize.apply(val);
      }
  })

  // Define a text icon called imageIcon.
  Froalaeditor.DefineIcon('italic', {SRC: '../../assets/italicicon.png', ALT: 'italic icon', template: 'image'});


    return (
        <>
            <div className={styles.editor_wrap}>
                <CustomTooltip label={label} text={tooltipText} showTooltip={showTooltip} />
                <div className={styles.editor_field}>
                    <FroalaEditor
                        id= "froala-editor-custom"
                        tag='textarea'
                        model={value?.toString() || ""}
                        config={{
                            key: '3AC6eC4A3B3D3C3A2C5B-16UJHAEFZMUJOYGYQEa2d2ZJd1RAeF3C8B5B4E3C3B2G3A17A11==',
                            toolbarButtons: ['insertLink','textSizeCustom','align','textAlign', 'bold', 'italic'],
                            placeholderText: defaultText || "",
                            charCounterCount: false,
                            // fontSize: ['10', '12', '14', '16', '18', '24'],
                            fontSize: ['small', '10', '12', '14', '18', '30', '60', '96'],
                            linkAlwaysBlank: true,
                            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
                            quickInsertEnabled: false,
                            pastePlain: true,
                            imagePaste: false
                            // events : {
                            //     'commands.after' : function(fontSize, param1, param2) {
                            //         //editor.fontSize.apply('12px');
                            //         // this is the editor instance.
                            //     console.log(this.fontSize.apply('20px'));
                            //       console.log(fontSize);
                            //       console.log(param1);
                            //       console.log(param2);
                            //     }
                            //  }
                            //commands.after (fontSize)
                        }}
                        onModelChange={handleChange}
                        
                    />
                </div>
            </div>
        </>
    )
}

TextEditor.prototype =
{
    label: PropTypes.string,
    tooltipText: PropTypes.string,
    defaultText: PropTypes.string,
    value: PropTypes.string
}


TextEditor.defaultProps = [
    {
        label: "Title",
        tooltipText: "Title",
        defaultText: "Testing",
        value: "Testing",
    }
]