import { createContext, useState } from "react";

export const FilterStates = createContext();

const SelectorArea = ({ children }) => {
    const [filterIsOpen, filterState] = useState({
        status: false,
        component: ""
    });

    return (
        <FilterStates.Provider
            value={{
                filterIsOpen,
                filterState
            }}
        >
            {children}
        </FilterStates.Provider>
    );
};

export default SelectorArea;
