// local
export const WIX_BASE_URL = process.env.REACT_APP_WIX_BASE_URL

// dev old
// export const BASE_URL = 'https://prepurchase-api-test.stilyoapps.com/api/';

// dev new
export const BASE_URL = process.env.REACT_APP_BASE_URL;

// local cdn
// export const CDN_BASE_URL = '../../';

// dev cdn
export const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;

// LD
export const REACT_APP_LAUNCH_DARKLY_CLIENT_KEY = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_KEY
export const LAUNCH_DARKLY_ENABLE_DISCOUNT_KEY = process.env.REACT_APP_LAUNCH_DARKLY_ENABLE_DISCOUNT_KEY
export const LAUNCH_DARKLY_ENABLE_BANNER_KEY = process.env.REACT_APP_LAUNCH_DARKLY_ENABLE_BANNER_KEY
export const STATIC_SHOP = "jzdev.myshopify.com"
export const LAUNCH_DARKLY_ENABLE_MICROSERVICE_KEY = process.env.REACT_APP_LAUNCH_DARKLY_ENABLE_MICROSERVICE_KEY
export const LAUNCH_DARKLY_ENABLE_REVIEW_COLLECTOR = process.env.REACT_APP_LAUNCH_DARKLY_ENABLE_REVIEW_COLLECTOR

// Datadog
export const APPLICATION_ID = '49c10eac-1287-4105-9ad2-cdf07aea3ad7'
export const CLIENT_TOKEN = 'pubc2cba0a189513198f27334998e86cc66'
export const ENV = 'dev'
export const SERVICE = 'unified-editor'

// preview bundle URLs
export const CE_BUNDLE_URL = process.env.REACT_APP_CE_BUNDLE_URL

// microservice base url
export const MICROSERVICE_BASE_URL = process.env.REACT_APP_MICROSERVICE_BASE_URL

// crisp config
export const CRISP_WEBSITE_ID = "56918d27-713e-4f7f-9786-961982ae4884"

// For Review Collector
export const REVIEW_BASE_URL = process.env.REACT_APP_REVIEW_BASE_URL;
export const APP_STORE_URL = process.env.REACT_APP_APP_STORE_URL;
export const REVIEW_INTERVAL_IN_MIN = process.env.REACT_APP_REVIEW_INTERVAL_IN_MIN;
export const IS_REVIEW_DISMISSABLE =
  process.env.REACT_APP_IS_REVIEW_DISMISSABLE === 'true' ? true : false;

