
import { Button } from '@chakra-ui/react';
import { Icon } from '@shopify/polaris';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import { useContext } from 'react';
import { CDN_BASE_URL } from '../../config';
import { WIX } from '../../Constant';
import { PageData } from '../../ContextAPI/context';
import styles from './CloseButton.module.css'

const CloseButton = ({ onClick }) => {
  const { type } = useContext(PageData)
  return (
    <>
      {type === WIX ?
        <Button className={styles.close_button_wrap} onClick={onClick} variant='ghost'>
          <img src={CDN_BASE_URL + "assets/close.svg"} alt="close button" />
        </Button>
        :
        <span className={styles.close_button} onClick={onClick}>
          <Icon source={CancelSmallMinor} color="base" />
        </span>
      }
    </>
  );
};

export default CloseButton;
