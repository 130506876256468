import React, { useState, useEffect, useContext } from 'react';
import { PageData } from '../ContextAPI/context';
import { CreatedNewObj, getTargetedValue, updateTargetedValue, getSelectedSection, checkTargetConditions } from '../Actions/common';
import TextField from './commonComp/TextField';
import CustomSubheader from './CustomSubheader';
import { PRODUCT_UPSELL, UPDATE_STATE } from '../Constant';
import Button from './commonComp/Button';
import { cloneDeep } from 'lodash';
import CustomHeader from './commonComp/CustomHeader';
import InputCheckbox from './commonComp/InputCheckbox';
import InputSelect from './commonComp/InputSelect';

const SuccessErrorBanner = props => {
  const { PrePurchaseConfigSetting, unifiedEditor, dispatch, bannerStatus, setBannerStatus, handleEditorChanges } = useContext(PageData);
  const [titleComp, setTitle] = useState('');
  const [objComp, objstate] = useState([props]);
  const [state, setState] = useState();
  const [blockId, setblockId] = useState()
  const { id, sectionID, topSectionID, extentionID, target } = props.type;

  useEffect(() => {
    const fetchData = async () => {
      setState(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
      const latestPrepurchaseSettings = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
      const data = getTargetedValue(latestPrepurchaseSettings, target, id, sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections)
      setblockId(data?.id)
      objstate(obj)
      setTitle(obj?.blockName);
    }

    fetchData()

  }, [PrePurchaseConfigSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID]);

  const handleActions = async (e, target) => {
    let value = e;
    const data = await CreatedNewObj(state);
    const dataValue = await updateTargetedValue(data, target, value, id, sectionID, topSectionID, extentionID);
    let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
    dispatch({ type: UPDATE_STATE, payload: {...dataValue, editedData} });
  };

  const handleClick = (type) => {
    let obj = cloneDeep(bannerStatus)
    obj[blockId] = type
    if (bannerStatus?.[blockId] === type) {
      setBannerStatus({})
    } else {
      setBannerStatus(obj)

    }
  }

  try {
    return (
      <>
        <div className="rightbar-content">
          {objComp.count > 0 ? (
            <>
              <CustomHeader label={titleComp} />
              {objComp.blockSettings.map((x, i) => {
                return (
                  <React.Fragment key={i}>

                    <div>
                      <CustomSubheader label={x.label} />
                      {x.settingGroups.map((sub, index) => {
                        if (
                          sub.conditional === true && !checkTargetConditions(state, `${target}.${sub.target}`, sub.condition, id, sectionID, topSectionID, extentionID, sub.conditionValue)
                        ) {
                          return null;
                        } else {
                          return (

                            <div key={index}>
                              {sub.type === 'checkbox' ? (
                                <InputCheckbox
                                  label={sub.label}
                                  checked={getTargetedValue(state, `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                  onChange={e => handleActions(e, `${target}.${sub.target}`)}
                                  showTooltip={sub.showTooltip}
                                  tooltipText={sub.tooltipText}
                                  helpText={sub.helpText}
                                />
                              ) : sub.type === 'dropdown' ? (
                                <InputSelect
                                  isShowTooltip={sub.showTooltip}
                                  tooltipContent={sub.tooltipText}
                                  label={sub.label}
                                  options={sub.options}
                                  onChange={e => handleActions(e, `${target}.${sub.target}`)}
                                  value={getTargetedValue(state, `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                />
                              ) : sub.type === 'textField' ? (
                                <TextField
                                  label={sub.label}
                                  value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                  onChange={e => handleActions(e, `${target}.${sub.target}`)}
                                  toolTipIsEnable={sub.showTooltip}
                                  tooltip={sub.tooltipText}
                                />
                              ) : sub.type === 'button' ? (
                                <Button
                                  text={bannerStatus?.[blockId] === sub.status ? 'Preview the upsell section' : sub.label}
                                  onClick={() => handleClick(sub.status)}
                                />
                              ) : null}
                            </div>


                          );
                        }
                      })}
                      {objComp?.blockSettings?.length - 1 !== i && <div className='content-divider' />}
                    </div>

                  </React.Fragment>
                );
              })}
            </>
          ) : null}
        </div>
      </>
    );
  } catch (err) {
    return <></>;
  }
};

export default SuccessErrorBanner;
