import PropTypes from 'prop-types';
import React from 'react';

const Switch = ({ isOn, handleToggle, onColor, offColor }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        style={{ background: isOn ? onColor : offColor }}
        className="react-switch-label"
        htmlFor={`react-switch-new`}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

Switch.prototype = {
  isOn: PropTypes.boolean,
  handleToggle: PropTypes.func,
  onColor: PropTypes.string,
  offColor: PropTypes.string
}

export default Switch;