import React from 'react'
import { Menu, MenuButton, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react'
import styles from './AddSectionDropdown.module.css'
import { CDN_BASE_URL } from '../../../config';

const AddSectionDropdown = ({ title, options, optionTitle, handleChange, isLink, isBlock }) => {

    const handleSelect = async (value) => {
        if (handleChange)
            handleChange(value)
    };

    return (
        <Menu autoSelect={false}>
            <MenuButton className={`${styles.dropdown_button} ${isLink ? styles.link_button : ''}`}>
                <div className={`${styles.dropdown_wrap} ${isBlock ? styles.block_button : ''}`}>
                    {isLink ?
                        <img src={CDN_BASE_URL + `assets/plus-icon.svg`} alt={`plus icon`} />
                        :
                        <img src={CDN_BASE_URL + `assets/plus-rounded.svg`} alt={`plus icon`} />
                    }
                    <div className={`${styles.dropdown_title} ${isLink ? styles.link_button_title : ''}`}>{title}</div>
                </div>
            </MenuButton>
            <MenuList className={styles.menulist_wrap}>
                <MenuGroup className={styles.options_title} title={optionTitle}>
                    {options?.map((item) => {
                        return (
                            <MenuItem key={item.value} value={item.value} onClick={() => handleSelect(item.value)} >
                                <div className={styles.dropdown_options_wrap}>
                                    <img className={styles.option_icon} src={CDN_BASE_URL + `assets/${item.icon}`} alt={`plus icon`} />
                                    <div className={styles.option_label}>{item.label}</div>
                                </div>
                            </MenuItem>
                        )
                    })}

                </MenuGroup>
            </MenuList>
        </Menu>
    )
}

export default AddSectionDropdown