import { Modal, TextContainer } from '@shopify/polaris';


function ModalComponent({ active, buttonRef, handleClose, handleSubmit, content, title }) {

    const handleTransitionEnd = () => {
        document.querySelector('.Polaris-Modal-Dialog').setAttribute('tabindex', null);
    }

    return (
        <div style={{ height: '500px' }}>
            <Modal
                activator={buttonRef}
                open={active}
                onClose={handleClose}
                title={title}
                primaryAction={{
                    content: 'Exit without saving',
                    onAction: handleSubmit,
                }}
                onTransitionEnd={handleTransitionEnd}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleClose,
                    },
                ]}
            >
                <Modal.Section>
                    <TextContainer>
                        <p>
                            {content}
                        </p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        </div>
    );
}

export default ModalComponent