import React, { useState, useContext, useEffect } from 'react';
import { Select } from '@shopify/polaris';
import '../style/index.css'
import '../style/customPolaris.css';
import '../style/product.css';
import { PageData } from '../ContextAPI/context'
import { CreatedNewObj, updateTargetedValue, getTargetedValue, getSelectedSection, checkTargetConditions } from '../Actions/common';
import ProductSwitcher from './Products/ProductSwitcher';
import { FilterStates } from "./SelectorArea"
import { UPDATE_STATE } from '../Constant';
import CustomHeader from './commonComp/CustomHeader';
import CustomTooltip from './commonComp/CustomTooltip';

const Product = (props) => {
  const [titleComp, setTitle] = useState("")
  const [objComp, objstate] = useState([])
  const [state, setState] = useState()
  const { PrePurchaseConfigSetting, unifiedEditor, dispatch } = useContext(PageData)
  const { filterIsOpen } = useContext(FilterStates)
  const { sectionID, topSectionID, extentionID, target, id, main } = props.type

  useEffect(() => {
    const fetchData = async () => {
      const latestPrepurchaseSettings = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
      const data = getTargetedValue(latestPrepurchaseSettings, target, '', sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections)
      setState(latestPrepurchaseSettings)
      setTitle(obj?.sectionName);
      objstate(obj)
    }

    fetchData()
  }, [PrePurchaseConfigSetting, unifiedEditor, target, sectionID, topSectionID, extentionID]);

  const handleChange = async (e, target) => {
    let value = e;
    const latestPrepurchaseSettings = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
    const updatedPrepurchaseSettings = await updateTargetedValue(latestPrepurchaseSettings, target, value, id, sectionID, topSectionID, extentionID);
    dispatch({ type: UPDATE_STATE, payload: updatedPrepurchaseSettings });
  };

  try {

    return (
      <>

        {<div>
          {filterIsOpen.status === false ?
            <div>
              <div className='mt-3 mb-3 m-3'>
                <CustomHeader label={titleComp} />
              </div>
              {
                objComp?.sectionSettings?.map((x, i) => {
                  return (
                    <React.Fragment key={i}>
                      {
                        x.conditional === false ?
                          <div className='mt-3 m-3'>
                            <CustomTooltip
                              label={x.label}
                              text={x.tooltipText}
                              rootStyles={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '0.375rem'
                              }}
                            />

                            <Select
                              options={x.options}
                              onChange={(e) => {
                                handleChange(e, `${target}.${x.target}`)
                              }}
                              value={getTargetedValue(state, `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                            />
                          </div>
                          :
                          <ProductSwitcher value={{ obj: x, type: checkTargetConditions(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${x.target}`, x.condition, id, sectionID, topSectionID, extentionID,), sectionID, topSectionID, extentionID, target: `${target}.${x.target}`, main }} />

                      }
                    </React.Fragment>
                  )
                })
              }
            </div> : filterIsOpen.component}
        </div>}

      </>
    )
  }
  catch (err) {
    return (
      <>
        Error
      </>
    )
  }

}

export default Product;