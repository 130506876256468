import React from 'react'
import styles from './CustomSubheader.module.css'

function CustomSubheader({ label }) {
    return (
        <>
            <div className={styles.custom_subheader} >
                {label}
            </div>
        </>
    )
}

export default CustomSubheader