import { Button, Popover, ActionList } from '@shopify/polaris';
import _ from 'lodash';
import { useState, useCallback, useMemo } from 'react';

const PopoverWithActionList = ({ value, options, suffix, onChange, isHideStyles, className }) => {
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const activator = (
    <Button onClick={toggleActive} disclosure>
      {value} {suffix}
    </Button>
  );

  const handleChange = useCallback((e) => {
    onChange(e)
    setActive(false)
  }, [onChange])

  const getSections = useMemo(() => {
    let newOptions = _.cloneDeep(options)
    newOptions[0].items = newOptions[0].items?.map((item) => item.value === value ? { ...item, active: true, onAction: () => handleChange(item.value) } : { ...item, onAction: () => handleChange(item.value) })
    return newOptions
  }, [options, handleChange, value])

  return (
    <div style={isHideStyles ? {} : { height: '22px' }}>
      <Popover
        active={active}
        activator={activator}
        onClose={toggleActive}
      >
        <div className={className ? className : 'popover-actionlist-content'}>
          <ActionList
            actionRole="menuitem"
            sections={getSections}
            preferredAlignment='right'
          />
        </div>
      </Popover>
    </div>
  );
}

export default PopoverWithActionList