import React, { useContext, useState, useEffect } from 'react';
import { PageData } from "../ContextAPI/context";
import { getSelectedSection, CreatedNewObj, getTargetedValue, updateTargetedValue, getTargetedCondition } from '../Actions/common';
import { PRODUCT_UPSELL, UPDATE_STATE } from '../Constant';
import TextField from './commonComp/TextField';
import CustomHeader from './commonComp/CustomHeader';
import InputCheckbox from './commonComp/InputCheckbox';
import InputSelect from './commonComp/InputSelect';

const VariantPicker = (props) => {

    const { PrePurchaseConfigSetting, unifiedEditor, dispatch, handleEditorChanges } = useContext(PageData)
    const [titleComp, setTitle] = useState("")
    const [objComp, objstate] = useState([])
    const [state, setState] = useState()

    const { id, sectionID, topSectionID, extentionID, target } = props.type;

    useEffect(() => {
        const fetchData = async () => {
            setState(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
            const latestPrepurchaseSettings = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
            const data = getTargetedValue(latestPrepurchaseSettings, target, id, sectionID, topSectionID, extentionID)
            const obj = await getSelectedSection(data, unifiedEditor.sections)

            objstate(obj)
            setTitle(obj?.blockName);
        }
        fetchData()
    }, [PrePurchaseConfigSetting, state, unifiedEditor, target, id, sectionID, topSectionID, extentionID])


    const handleChange = async (e, target) => {
        const data = await CreatedNewObj(state)
        const dataValue = await updateTargetedValue(data, target, e, id, sectionID, topSectionID, extentionID)
        let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
        dispatch({ type: UPDATE_STATE, payload: { ...dataValue, editedData } })
    }

    try {
        return (
            <>
                <div className="section-details-wrap">
                    {objComp.count > 0 ? <>
                        <CustomHeader label={titleComp} />
                        {
                            objComp.blockSettings.map((x, i) => {
                                if (x.conditional && (getTargetedCondition(state, `${target}.${x.target}`, x.condition, id, sectionID, topSectionID, extentionID) === false || getTargetedValue(state, `${target}.isEnable`, id, sectionID, topSectionID, extentionID) === false)) {
                                    return null;
                                } else {
                                    return (
                                        <React.Fragment key={i}>
                                            {
                                                x.type === "checkbox" ?
                                                    <InputCheckbox
                                                        label={x.label}
                                                        checked={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                                                        onChange={e => handleChange(e, `${target}.${x.target}`)}
                                                        showTooltip={x.showTooltip}
                                                        tooltipText={x.tooltipText}
                                                    />
                                                    :
                                                    x.type === "dropdown" ?
                                                        <InputSelect
                                                            isShowTooltip={x.showTooltip}
                                                            tooltipContent={x.tooltipText}
                                                            label={x.label}
                                                            options={x.options}
                                                            onChange={e => handleChange(e, `${target}.${x.target}`)}
                                                            value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                                                        />

                                                        : x.type === "textField" &&
                                                        <TextField
                                                            label={x.label}
                                                            value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                                                            onChange={e => handleChange(e, `${target}.${x.target}`)}
                                                            toolTipIsEnable={x.showTooltip}
                                                            tooltip={x.tooltipText}
                                                        />
                                            }
                                        </React.Fragment>
                                    )
                                }
                            })
                        }
                    </> : null}

                </div>
            </>
        )
    } catch (err) {
        return <></>
    }
}

export default VariantPicker