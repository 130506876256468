
Object.defineProperty(exports, "__esModule", { value: true });
exports.maybeCall = void 0;
function maybeCall(maybeFunc) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if (typeof maybeFunc === 'function') {
        return maybeFunc.apply(void 0, args);
    }
    else {
        return maybeFunc;
    }
}
exports.maybeCall = maybeCall;
