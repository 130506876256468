import React, { useContext } from "react";
import { Icon, Tooltip } from "@shopify/polaris";
import { QuestionMarkMinor } from "@shopify/polaris-icons";
import { PageData } from "../../../ContextAPI/context";
import { WIX } from "../../../Constant";
import { QuestionIcon } from "@chakra-ui/icons";
import { Tooltip as ChakraTooltip } from '@chakra-ui/react'
import styles from './CustomTooltip.module.css'

function CustomTooltip({ label, text, rootStyles, showTooltip }) {
    const { type } = useContext(PageData)

    return (
        <>
            {type === WIX ?
                <div className={styles.label_wrap} style={{ ...rootStyles }}>
                    <label>{label}</label>
                    {showTooltip &&
                        <ChakraTooltip label={text}>
                            <span className="ue_infolabel">
                                <QuestionIcon width={'16px'} height={'16px'} />
                            </span>
                        </ChakraTooltip>
                    }
                </div>
                :
                <div className={styles.custom_tooltip} style={{ ...rootStyles }}>
                    <label>{label}</label>
                    {showTooltip &&
                        <Tooltip content={text}>
                            <span className="ue_infolabel">
                                <Icon source={QuestionMarkMinor} color="base" />
                            </span>
                        </Tooltip>
                    }
                </div>
            }
        </>
    );
}

export default CustomTooltip;
