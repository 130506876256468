import React, { useContext, useState, useEffect } from 'react';
import { Select, Tooltip, Icon } from "@shopify/polaris"
import { QuestionMarkMinor } from "@shopify/polaris-icons";
import { PageData } from "../ContextAPI/context";
import { CreatedNewObj, getTargetedValue, updateTargetedValue, getSelectedSection } from '../Actions/common';
import { UPDATE_STATE } from '../Constant';
import CustomHeader from './commonComp/CustomHeader';

const Vendor = (props) => {

    const { PrePurchaseConfigSetting, unifiedEditor, dispatch } = useContext(PageData)
    const [titleComp, setTitle] = useState("")
    const [objComp, objstate] = useState([])
    const { id, sectionID, topSectionID, extentionID, target } = props.type;


    useEffect(() => {
        const fetchData = async () => {
            const latestPrepurchaseSettings = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
            const data = getTargetedValue(latestPrepurchaseSettings, target, id, sectionID, topSectionID, extentionID)
            const obj = await getSelectedSection(data, unifiedEditor.sections)
            setTitle(obj?.blockName);
            objstate(obj);
        }

        fetchData()
    }, [PrePurchaseConfigSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID]);



    const handleSelectChange = async (e, target) => {
        let value = e;
        const latestPrepurchaseSettings = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
        const updatedPrepurchaseSettings = await updateTargetedValue(latestPrepurchaseSettings, target, value, id, sectionID, topSectionID, extentionID);
        dispatch({ type: UPDATE_STATE, payload: updatedPrepurchaseSettings });
    };

    try {
        return (
            <>
                <div className="m-3">
                    {objComp.count > 0 ? <>
                        <CustomHeader label={titleComp} />
                        {
                            objComp.blockSettings.map((x, i) => {
                                return (
                                    <div className="mt-3" key={i}>
                                        <div className="ue__icon_set">
                                            <Tooltip content={x.tooltipText}>
                                                <span className="ue_infolabel">
                                                    <Icon source={QuestionMarkMinor} color="base" />
                                                </span>
                                            </Tooltip>
                                            <Select
                                                label={x.label}
                                                options={x.options}
                                                onChange={(e) => handleSelectChange(e, `${target}.${x.target}`)}
                                                value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${x.target}`, id, sectionID, topSectionID, extentionID)}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </> : null}
                </div>
            </>
        )
    } catch (err) {
         return <>err</> }
}

export default Vendor