import { Button, Modal as ChakraModal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react'
import { useState, useCallback, useEffect, useContext } from 'react';
import { PageData } from '../../ContextAPI/context';
import { Modal } from '@shopify/polaris';
import { WIX } from '../../Constant';
import { ShopifyReviewForHelpModal } from '../ReviewCollector/ReviewCollector';

const HelpModal = () => {
    const [active, setActive] = useState(false);
    const { helpModalData, setHelpModalData, type } = useContext(PageData)
    // const [isFrameLoad, setIsFrameLoad] = useState(false)

    useEffect(() => {
        if (helpModalData?.link) {
            setActive(true)
        }
    }, [helpModalData])

    const handleChange = useCallback(() => {
        setHelpModalData()
        setActive(false)
    }, [setHelpModalData]);

    const activator = '';

    const ComponentStyles = () => {
        return (
            <>
                {active &&
                    <style jsx='false' global='false'>
                        {`
                           .model-main .modal-dialog {
                               z-index: 0!important;
                           }
    
                           .modal-backdrop{ 
                               z-index: 0!important;
                           }
                           
                           .Polaris-DisplayText{
                              text-align:center
                           }
         
                           @media screen and (min-width: 48.0625em) {
                             .Polaris-Modal-Dialog__Modal {
                                 max-width: 900px;
                                //  height: calc(100vh - 86px);
                                 max-height:100%
                             }
                           }
        
                           .Polaris-Modal__IFrame{
                              max-width:100% ;
                              width: 100%;
                              height:100%
                           }

                           .Polaris-Modal__BodyWrapper > iframe{
                              height:100% !important
                           }

                           .Polaris-Modal__IFrame{
                            height: 100% !important
                           }
                 
                         `}
                    </style>
                }

                {/* {!isFrameLoad && active &&
                    <style jsx='false' global='false'>
                        {`
                   
                           @media screen and (min-width: 48.0625em) {
                             .Polaris-Modal-Dialog__Modal {
                                height: calc(100vh - 86px);
                             }
                           }
                 
                        `}
                    </style>
                } */}
            </>
        )
    }

    // const handleLoad = () => {
    //     setIsFrameLoad(true)
    // }


    return (
        <div>
            {type === WIX ?
                <ChakraModal onClose={handleChange} size={'4xl'} isOpen={active} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{helpModalData?.title}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <iframe className='help-modal-iframe' title={helpModalData?.title} src={helpModalData?.link} />
                        </ModalBody>
                        <ModalFooter>
                            <Stack spacing={3} direction='row' align='center'>
                                <Button colorScheme='gray' variant='outline' onClick={handleChange}>Close</Button>
                            </Stack>
                        </ModalFooter>
                    </ModalContent>
                </ChakraModal>
                :
                <>
                    <ComponentStyles />
                    <Modal
                        activator={activator}
                        open={active}
                        onClose={handleChange}
                        title={helpModalData?.title}
                        // src={helpModalData?.link}
                        // onIFrameLoad={handleLoad}
                        secondaryActions={[
                            {
                                content: 'Close',
                                onAction: handleChange,
                            },
                        ]}
                    >
                        <iframe
                            width="100%"
                            height="500px"
                            title="help article"
                            src={helpModalData?.link}
                        />
                        <ShopifyReviewForHelpModal />

                    </Modal>
                </>
            }
        </div>
    );
}

export default HelpModal