import React, { useCallback, useState, useContext, useEffect } from "react";
import { Button, DropZone, Banner, List, ButtonGroup, Icon } from "@shopify/polaris";
import { CreatedNewObj, getSelectedSection, getTargetedCondition, getTargetedValue, getTargetString, updateTargetedValue } from "../Actions/common";
import { PageData } from "../ContextAPI/context";
import { RenderComponents } from "./globalSettings/renderComponents";
import { fetchShopifyProductImages, uploadShopifyProductImages } from "../Service";
import { PRODUCT_UPSELL, SECTION_HELP_MODAL_DATA, UPDATE_STATE, WIX } from "../Constant";
import SectionHeader from "./Products/SectionHeader";
import Divider from "./Divider";
import { CircleTickMajor } from "@shopify/polaris-icons";
import CustomTooltip from "./commonComp/CustomTooltip";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Button as ChakraButton } from '@chakra-ui/react';
import ChakraImageUpload from "./commonComp/ChakraImageUpload";

const ProductSelectedData = props => {
    const { type } = useContext(PageData)
    let { selectImage, setSelectImage, selectedFile, handleDelete, setData } = props;

    const handleImageChange = () => {
        document.querySelector(".right-section")?.classList.remove("overflow_vertical_scroll")
        document.querySelector(".bottom_container")?.classList?.add("removeDiv")
        document.querySelector(".right-section-title")?.classList?.add("removeDiv")
        setSelectImage(!selectImage)
        setData()
    }

    return (
        <div className="image_component_holder">
            {selectedFile ? (
                <>
                    <CustomTooltip label="Select Image" text="Image" rootStyles={{ marginBottom: '4px' }} />
                    <div className="selected drop-upload-image-box">
                        <div className="drop-upload-details">
                            <img src={selectedFile.public_url} alt="selected file" />
                            <div className="selected-buttons">
                                {type === WIX ?
                                    <>
                                        <ChakraButton className="chakra-btn" colorScheme='gray' variant='outline' onClick={handleImageChange}>
                                            Change
                                        </ChakraButton>
                                        <ChakraButton className="chakra-btn" colorScheme='gray' variant='outline' onClick={handleDelete}>
                                            Delete
                                        </ChakraButton>
                                    </>
                                    :
                                    <>
                                        <Button onClick={handleImageChange}>Change</Button>
                                        <Button onClick={handleDelete}>Delete</Button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="select-image">
                    <CustomTooltip label="Select Image" text="Image" rootStyles={{ marginBottom: '4px' }} />
                    <div className="select-image-box">
                        {type === WIX ?
                            <ChakraButton className="image-select-button" variant='outline' onClick={handleImageChange}>Select Image</ChakraButton>
                            :
                            <Button fullWidth onClick={handleImageChange}>Select Image</Button>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

const ImageComponent = (props) => {
    const [selectImage, setSelectImage] = useState(false);
    const [files, setFiles] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [selectingFile, setSelectingFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const hasError = rejectedFiles.length > 0;
    const [maxSlides, setMaxSlides] = useState(5)
    const [selectorData, setSelectorData] = useState({})
    const { PrePurchaseConfigSetting, unifiedEditor, dispatch, setHelpModalData, type, isMicroService, handleEditorChanges } = useContext(PageData)
    const [objComp, objstate] = useState([])
    const [state, setState] = useState()

    const { id, sectionID, topSectionID, extentionID, target, main } = props.type;


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await fetchShopifyProductImages(isMicroService);
                setFiles([...res.data]);
            } catch (e) { }
        };

        fetchData();
    }, [isMicroService])

    useEffect(() => {
        const fetchData = async () => {
            setState(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
            const latestPrepurchaseSettings = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
            const data = getTargetedValue(latestPrepurchaseSettings, target, id, sectionID, topSectionID, extentionID);
            const obj = await getSelectedSection(data, unifiedEditor.sections);

            //update imageQty by selected product
            if (obj.productImageTarget) {
                let newTarget = target
                newTarget = getTargetString(newTarget, 4, 4)
                let newdata = getTargetedValue(latestPrepurchaseSettings, `${newTarget}.${obj.productImageTarget}`, id, sectionID, topSectionID, extentionID);
                if (newdata && newdata?.images?.length > 0) {
                    setMaxSlides(newdata.images.length || 5)
                }
            }

            objstate(obj);
        };

        fetchData();
    }, [PrePurchaseConfigSetting, target, id, sectionID, topSectionID, extentionID, unifiedEditor.sections])

    useEffect(() => {
        if (!objComp || !objComp.blockSettings || !PrePurchaseConfigSetting || PrePurchaseConfigSetting.length === 0) {
            return
        }

        const ts = objComp.blockSettings.find(setting => setting.type === 'selector');
        const updatedVal = getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${ts?.target}`, id, sectionID, topSectionID, extentionID);

        if (!updatedVal || updatedVal === " ") {
            setSelectedFile();
        } else {
            setSelectedFile({ public_url: updatedVal });
        }
    }, [objComp, PrePurchaseConfigSetting, target, id, sectionID, topSectionID, extentionID]);

    const handleDrop = useCallback(async (_droppedFiles, acceptedFiles, rejectedFiles) => {
        setUploading(true);
        setFiles(files => [...files, ...acceptedFiles.map(file => ({ loading: true }))]);

        try {
            const savedFiles = await uploadShopifyProductImages(acceptedFiles, isMicroService);
            setFiles(files => [...files.filter(file => !file.loading), ...savedFiles.data]);
        } catch (e) { }

        setRejectedFiles(rejectedFiles);
        setUploading(false);
    }, [isMicroService]);

    const uploadedFiles = files.length > 0 && (
        files.map((file, index) => {
            const isSelected = selectingFile != null && selectingFile.public_url === file.public_url
            return (
                <div onClick={() => setSelectingFile(file)} key={index}
                    className={"product-img " + (isSelected ? 'selected-img ' : '') + (file.loading ? 'loading' : '')}>
                    {file.loading ? <img src="assets/spinner.svg" alt="loading" /> : (
                        <>
                            <img src={file.public_url} alt="selected file" />
                            {isSelected && type !== WIX && <span className="tick-icon"><Icon source={CircleTickMajor} color='interactive' /></span>}
                            {isSelected && type === WIX &&
                                <span className="tick-icon">
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#4C78C8" />
                                    </svg>
                                </span>
                            }
                        </>
                    )}
                </div>
            )
        })
    );

    const handleChakraDrop = useCallback(async (acceptedFiles) => {
        let arr = Array.from(Array(acceptedFiles.length).keys())
        setUploading(true);
        setFiles(files => [...files, ...arr.map(file => ({ loading: true }))]);

        try {
            const savedFiles = await uploadShopifyProductImages(acceptedFiles, isMicroService);
            setFiles(files => [...files.filter(file => !file.loading), ...savedFiles.data]);
        } catch (e) {
            console.warn('Image upload error', e);
        }

        setUploading(false);
    }, [isMicroService]);

    const handleSelectImage = async (file) => {
        const targetSettings = objComp.blockSettings.find(setting => setting.type === 'selector');
        let newtarget = `${target}.${targetSettings.target}`
        const data = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
        let dataValue = await updateTargetedValue(data, newtarget, file.public_url, id, sectionID, topSectionID, extentionID);

        //update imageQty by selected product
        if (selectorData.updateConditionTarget) {
            const imageQty = newtarget.slice(0, newtarget.lastIndexOf('.') + 1) + selectorData.updateConditionTarget;
            dataValue = await updateTargetedValue(dataValue, imageQty, 1, id, sectionID, topSectionID, extentionID)
        }

        let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
        dispatch({ type: UPDATE_STATE, payload: { ...dataValue, editedData } })

        setSelectedFile(file);
        setSelectingFile(null);
        setSelectImage(false);
        document.querySelector(".right-section")?.classList.add("overflow_vertical_scroll")
        document.querySelector(".bottom_container")?.classList?.remove("removeDiv")
        document.querySelector(".right-section-title")?.classList?.remove("removeDiv")
    }

    const handleCancel = useCallback(() => {
        document.querySelector(".right-section")?.classList.add("overflow_vertical_scroll")
        document.querySelector(".bottom_container")?.classList?.remove("removeDiv")
        document.querySelector(".right-section-title")?.classList?.remove("removeDiv")
        setSelectImage(!selectImage)
    }, [selectImage]);

    const errorMessage = hasError && (
        type === WIX ?
            <Alert status='error'>
                <AlertIcon />
                <AlertTitle>Images couldn’t be uploaded</AlertTitle>
                <AlertDescription>File type must be .gif, .jpg, .png or .svg.</AlertDescription>
            </Alert>
            :
            <Banner title="The following images couldn’t be uploaded:" status="critical">
                <List type="bullet">
                    {rejectedFiles.map((file, index) => (
                        <List.Item key={index}>{`"${file.name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}</List.Item>
                    ))}
                </List>
            </Banner>
    );

    const handleClick = () => {
        setHelpModalData(SECTION_HELP_MODAL_DATA['Image'])
    }

    useEffect(() => {
        return () => handleCancel()
    }, [main, handleCancel]);

    const handleDelete = async () => {
        const targetSettings = objComp.blockSettings.find(setting => setting.type === 'selector');
        let newtarget = `${target}.${targetSettings.target}`
        const data = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
        let dataValue = await updateTargetedValue(data, `${target}.${targetSettings.target}`, '', id, sectionID, topSectionID, extentionID);

        //update imageQty by selected product
        if (selectorData.updateConditionTarget) {
            const imageQty = newtarget.slice(0, newtarget.lastIndexOf('.') + 1) + selectorData.updateConditionTarget;
            dataValue = await updateTargetedValue(dataValue, imageQty, maxSlides, id, sectionID, topSectionID, extentionID)
        }

        let editedData = handleEditorChanges('edit', { sectionID, type: PRODUCT_UPSELL })
        dispatch({ type: UPDATE_STATE, payload: { ...dataValue, editedData } })
        setSelectedFile(null)
    }

    return (
        <>
            {!selectImage ?
                (
                    <div>
                        <SectionHeader title={!selectImage ? "Image" : "Images"} rootStyles={{ padding: '1rem', paddingBottom: '0px' }} />
                        {!selectImage && <span onClick={handleClick} className='custome-header-link image-block-link'>Learn more about image </span>}
                        <div className="image-section-container image-fields-wrap">
                            <div className='content-divider' />
                            {objComp?.blockSettings === undefined ?
                                "testing"
                                :
                                objComp?.blockSettings?.map((x, i) => {
                                    return (

                                        <div key={i}>
                                            {getTargetedCondition(state, `${target}.${x.target}`, x.condition, id, sectionID, topSectionID, extentionID) === "custom_image" && x.conditional === true ?
                                                x.type === "selector"
                                                    ?

                                                    <ProductSelectedData selectImage={selectImage} setSelectImage={setSelectImage} setData={() => setSelectorData(x)} selectedFile={selectedFile} setSelectedFile={setSelectedFile} handleDelete={handleDelete} />
                                                    :

                                                    <RenderComponents max={maxSlides} target={target} data={x} type={x.type} id={`${x.type}${i}`} open={true} data_id={id} sectionID={sectionID} topSectionID={topSectionID} extentionID={extentionID} />
                                                :
                                                x.conditional === false ?
                                                    <RenderComponents max={maxSlides} target={target} data={x} type={x.type} id={`${x.type}${i}`} open={true} data_id={id} sectionID={sectionID} topSectionID={topSectionID} extentionID={extentionID} />

                                                    : null
                                            }
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                )
                : (
                    <div>
                        <SectionHeader title={!selectImage ? "Image" : "Images"} onClose={() => handleCancel()} />

                        <Divider />

                        <div className="image-section-container image-selector-wrap">
                            <div className="image-error-msg">{errorMessage}</div>
                            <div className="images-container product-image-list">
                                <div className="product-img">
                                    {type === WIX ?
                                        <ChakraImageUpload onDrop={handleChakraDrop} />
                                        :
                                        <DropZone accept="image/*" type="image" onDrop={handleDrop} disabled={uploading}>
                                            <div className="drop-upload-image-box">
                                                <div className="drop-upload-details">
                                                    <p>Upload</p>
                                                </div>
                                            </div>
                                        </DropZone>
                                    }
                                </div>
                                {uploadedFiles}
                            </div>
                            <div className="product-bottom-continer" style={{ maxWidth: '282px' }}>
                                <div className="image-buttons ">
                                    {type === WIX ?
                                        <>
                                            <ChakraButton colorScheme='gray' variant='outline' onClick={handleCancel}>Cancel</ChakraButton>
                                            <ChakraButton className={'styles'} colorScheme='teal' variant='solid' disabled={!selectingFile} onClick={() => handleSelectImage(selectingFile)}>Select</ChakraButton>
                                        </>
                                        :
                                        <ButtonGroup >
                                            <Button onClick={handleCancel}>Cancel</Button>
                                            <Button primary disabled={!selectingFile} onClick={() => handleSelectImage(selectingFile)}>Select</Button>
                                        </ButtonGroup>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    );
};

export default ImageComponent;
