import { Checkbox, Select, Tooltip, Icon } from '@shopify/polaris';
import { useState, useEffect, useContext } from 'react';
import { PageData } from '../ContextAPI/context';
import { QuestionMarkMinor } from '@shopify/polaris-icons';
import { CreatedNewObj, getTargetedValue, updateTargetedValue, getTargetedCondition, getSelectedSection } from '../Actions/common';
import TextField from './commonComp/TextField';
import CustomSubheader from './CustomSubheader';
import { UPDATE_STATE } from '../Constant';
import { TextEditor } from './commonComp/TextEditor';
import LocationBanner from './commonComp/LocationBanner';
import CustomHeader from './commonComp/CustomHeader';

const BannerSection = props => {
  const { PrePurchaseConfigSetting, unifiedEditor, dispatch, handleEditorChanges } = useContext(PageData);
  const [title, setTitle] = useState('');
  const [objComp, objstate] = useState([props]);
  const [state, setState] = useState();
  const { id, sectionID, topSectionID, extentionID, target } = props.type;

  useEffect(() => {
    const fetchData = async () => {
      setState(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
      const latestPrepurchaseSettings = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
      const data = getTargetedValue(latestPrepurchaseSettings, target, id, sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections)
      objstate(obj)
      setTitle(obj?.sectionName);
    }

    fetchData()

  }, [PrePurchaseConfigSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID]);

  const handleActions = async (e, target, prefix) => {
    let value = e;
    const data = await CreatedNewObj(state);
    if (prefix && value) {
      value = value?.trim()
    }
    const dataValue = await updateTargetedValue(data, target, value, id, sectionID, topSectionID, extentionID);
    if (dataValue) {
      let editedData = handleEditorChanges('edit', objComp)
      dispatch({ type: UPDATE_STATE, payload: {...dataValue, editedData} });
    }
  };

  try {
    return (
      <>
        <div className="rightbar-content">
          {objComp.count > 0 ? (
            <>
              <CustomHeader label={title} />
              {!topSectionID && <LocationBanner data={props.type} />}
              {objComp.sectionSettings.map((x, i) => {
                return (

                  <div key={i}>
                    <CustomSubheader label={x.label} />
                    {x.settingGroups.map((sub, index) => {
                      if (
                        getTargetedCondition(state, `${target}.${sub.target}`, sub.condition, id, sectionID, topSectionID, extentionID) !== true &&
                        getTargetedCondition(state, `${target}.${sub.target}`, sub.condition, id, sectionID, topSectionID, extentionID) !== 'custom_text' &&
                        sub.conditional === true
                      ) {
                        return null;
                      } else {
                        return (

                          <div key={index}>
                            {sub.type === 'checkbox' ? (
                              <div className="ue__icon_set flex items-center justify-content-between mb-3">
                                <Checkbox
                                  label={sub.label}
                                  checked={getTargetedValue(state, `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                  onChange={e => handleActions(e, `${target}.${sub.target}`)}
                                />
                              </div>
                            ) : sub.type === 'dropdown' ? (
                              <div className="mb-3">
                                <div className="ue__icon_set">
                                  {sub.showTooltip ? (
                                    <Tooltip content={XMLDocument.tooltip}>
                                      <span className="ue_infolabel">
                                        <Icon source={QuestionMarkMinor} color="base" />
                                      </span>
                                    </Tooltip>
                                  ) : null}
                                  <Select
                                    label={sub.label}
                                    options={sub.options}
                                    onChange={e => handleActions(e, `${target}.${sub.target}`)}
                                    value={getTargetedValue(state, `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                  />
                                </div>
                              </div>
                            ) : sub.type === 'dropdown' ? (
                              <div className="mb-3">
                                <div className="ue__icon_set">
                                  {sub.showTooltip ? (
                                    <Tooltip content={XMLDocument.tooltip}>
                                      <span className="ue_infolabel">
                                        <Icon source={QuestionMarkMinor} color="base" />
                                      </span>
                                    </Tooltip>
                                  ) : null}
                                  <Select
                                    label={sub.label}
                                    options={sub.options}
                                    onChange={e => handleActions(e, `${target}.${sub.target}`)}
                                    value={getTargetedValue(state, `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                  />
                                </div>
                              </div>
                            ) : sub.type === 'textField' ? (
                              <TextField
                                label={sub.label}
                                value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                onChange={e => handleActions(e, `${target}.${sub.target}`, sub.prefix)}
                                toolTipIsEnable={sub.showTooltip}
                                tooltip={sub.tooltipText}
                              />
                            ) : sub.type === 'textEditor' ? (
                              <TextEditor
                                label={sub.label}
                                tooltipText={sub.tooltipText}
                                defaultText={sub.defaultText}
                                showTooltip={sub.showTooltip}
                                value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                onChange={(e) => handleActions(e, `${target}.${sub.target}`)}
                              />
                            ) : null}
                          </div>


                        );
                      }
                    })}
                    {objComp?.sectionSettings?.length - 1 !== i && <div className='content-divider' />}
                  </div>

                );
              })}
            </>
          ) : null}
        </div>
      </>
    );
  } catch (err) {
    return <></>;
  }
};

export default BannerSection;
