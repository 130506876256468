import React from 'react'
import { SectionMajor } from '@shopify/polaris-icons';
import { Icon, Tooltip } from '@shopify/polaris';
import { CUSTOMIZATIONS } from '../Constant';
function LeftActionBar({ selectedAction, onChange }) {
    const buttons = [
        {
            label: CUSTOMIZATIONS,
            icon: SectionMajor
        }
        // {
        //     label: BRANDING,
        //     icon: PaintBrushMajor
        // }
    ]

    return (
        <div className='actionbar-content'>
            {buttons.map((item) => {
                return (
                    <Tooltip key={item.label} content={item.label}>
                        <div className={`actionbar-btn ${selectedAction === item.label ? 'active-actionbar-btn' : ''}`} onClick={() => onChange(item.label)}>
                            <Icon
                                source={item.icon}
                                color="base"
                            />
                        </div>
                    </Tooltip>
                )
            })}
        </div>
    )

}

export default LeftActionBar