import CloseButton from '../../CloseButton';
import styles from './SectionHeader.module.css';

const SectionHeader = ({ title, onClose, rootStyles }) => {
  return (
    <div className={styles.section_header} style={rootStyles}>
      <h2 className={styles.section_title}>{title}</h2>

      {onClose && <CloseButton onClick={onClose} />}
    </div>
  );
};

export default SectionHeader;
