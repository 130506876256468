import { useState, useEffect, useContext } from 'react';
import { PageData } from '../ContextAPI/context';
import { getTargetedValue, getSelectedSection } from '../Actions/common';
import CustomHeader from './commonComp/CustomHeader';

function LeftColumn(props) {
  const { PrePurchaseConfigSetting, unifiedEditor } = useContext(PageData);
  const [sectionTitle, setSectionTitle] = useState();
  const { id, sectionID, topSectionID, extentionID, target } = props?.type;

  useEffect(() => {
    const fetchData = async () => {
      const latestPrepurchaseSettings = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
      const data = getTargetedValue(latestPrepurchaseSettings, target, id, sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections)
      setSectionTitle(obj?.title);
    }

    fetchData()
  }, [PrePurchaseConfigSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID]);

  return (
    <div className="m-3">
      <CustomHeader label={sectionTitle} />
    </div>
  );
}

export default LeftColumn;
