import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import styles from './RightBarSkeleton.module.css'

function RightBarSkeleton() {

    const BodyText = () => {
        return (
            <>
                <Skeleton className={styles.small_description} height='10px' />
                <Skeleton className={styles.big_description} height='25px' style={{ marginBottom: '20px' }} />
            </>
        )
    }

    return (
        <div>
            <Skeleton className={styles.small_description} height='10px' />
            <div className='link-body'>
                <Skeleton className={styles.big_description} height='10px' />
            </div>

            <div className='content-divider' />
            <BodyText />
            <BodyText />
            <BodyText />
            <BodyText />
            <BodyText />
        </ div>
    );
}


export default RightBarSkeleton;