import PropTypes from 'prop-types';
import { ColorPicker as PolarisColorPicker, TextField, AppProvider, Popover } from '@shopify/polaris';
import { useEffect, useState } from 'react';

export const ColorPicker = (props) => {
    const { colorOnChange, allowAlpha, textOnChange, label, text, helpText } = props
    const [color, ColorState] = useState(props.color)
    const [hex, setHex] = useState(props.hex);
    const [popoverActive, setPopoverActive] = useState(false)

    useEffect(() => {
        ColorState(props.color)
        setHex(props.hex)
    }, [props.color, props.hex]);

    const handlePopoverOpen = () => {
        setPopoverActive(!popoverActive);
    }

    const activator = (
        <div className="colorSelector">
            <div className='flex'>
                <div className='colorblock'>
                    <div className='colorSelectorBox' style={{ backgroundColor: color }} onClick={handlePopoverOpen}></div>
                </div>
                <div className='valueBlock'>
                    <div className="color-labels">{label}</div>
                    <p>{color}</p>
                </div>
            </div>
            {helpText &&
                <div className='color-help-text'>{helpText}</div>
            }
        </div>

    );

    return (
        <AppProvider>

            <Popover
                active={popoverActive}
                activator={activator}
                onClose={handlePopoverOpen}
            >
                <Popover.Section>
                    <PolarisColorPicker
                        onChange={colorOnChange}
                        color={hex || ''}
                        allowAlpha={allowAlpha}
                    />
                </Popover.Section>
                <Popover.Section>
                    <div className='colorPickerDiv' >
                        <div className='flexColor'>
                            <div style={{
                                background: color,
                                padding: "20px",
                                borderRadius: "5px",
                                marginRight: "5px"
                            }}>
                            </div>
                            <div>
                                <TextField
                                    value={(text === "" ? color : text) || ""}
                                    onChange={textOnChange}
                                />
                            </div>
                        </div>
                    </div>
                </Popover.Section>
            </Popover>


        </AppProvider>
    );

}

ColorPicker.prototype = {
    top: PropTypes.string,
    color: PropTypes.string,
    status: PropTypes.bool,
    colorOnChange: PropTypes.func.isRequired,
    hex: PropTypes.string,
    allowAlpha: PropTypes.bool,
    textOnChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func.isRequired
}

export default ColorPicker