import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SECTION_LOCATION_HELP_MODAL_DATA } from '../../../Constant';
import { PageData } from '../../../ContextAPI/context';
import { checkDynamicRender, getTargetedValue, getTargetString } from '../../../Actions/common';
import styles from './LocationBanner.module.css';

const LocationBanner = (props) => {
    const { data } = props
    const { setHelpModalData } = useContext(PageData);
    const [isDynamicRender, setIsDynamicRender] = useState(false);
    const { id, sectionID, topSectionID, extentionID, target, location } = data
    const { PrePurchaseConfigSetting } = useContext(PageData);

    useEffect(() => {
        let newTarget = target
        newTarget = getTargetString(newTarget, 2, 2)
        const latestPrepurchaseSettings = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
        const extentsion = getTargetedValue(latestPrepurchaseSettings, newTarget, id, sectionID, topSectionID, extentionID)
        const dynamicRender = checkDynamicRender(extentsion);
        setIsDynamicRender(dynamicRender)
    }, [PrePurchaseConfigSetting, target, id, sectionID, topSectionID, extentionID])

    const handleClick = () => {
        setHelpModalData(SECTION_LOCATION_HELP_MODAL_DATA)
    }

    if (location === 'false') {
        return
    }

    return (
        <>
            {isDynamicRender ?
                <div className={styles.banner_wrap} >
                    <div className="ue__icon_set">
                        <span className={styles.location_banner_span}>Location to select in Shopify checkout editor</span>
                        <div className={styles.banner_box}>
                            {location}
                        </div>
                    </div>
                    <span onClick={handleClick} className='custome-header-link'>What is this?</span>
                </div>
                :
                <div className={styles.location_wrap}>You don’t need to select any location in Shopify checkout editor</div>

            }
            <div className='content-divider' />
        </>
    );
}

LocationBanner.prototype = {
    data: PropTypes.object,
};

export default LocationBanner;




