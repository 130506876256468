import React, { useState } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum'
import { APPLICATION_ID, CLIENT_TOKEN, ENV, REACT_APP_LAUNCH_DARKLY_CLIENT_KEY, SERVICE, STATIC_SHOP } from './config';
import JsonData from './ContextAPI/context';
import Layout from './component/Layout';
import { Spinner } from '@chakra-ui/react';

function App() {
  const [loading, setLoading] = useState(true)

  datadogRum.init({
    applicationId: APPLICATION_ID,
    clientToken: CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: SERVICE,
    env: ENV,
    version: '1.0.0',
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  datadogRum.startSessionReplayRecording();
  console.log("_____Reconvert Loaded :)_____")
  return (
    // Wraping unified editor's layout with prepurchase & unified json data
    <>
      {loading &&
        <div className='spinner-icon'>
            <Spinner />
        </div>
      }
      <JsonData handleLoading={setLoading} shopName={STATIC_SHOP}>
        {!loading && <Layout />}
      </JsonData>
    </>
  );
}

export default withLDProvider({
  clientSideID: REACT_APP_LAUNCH_DARKLY_CLIENT_KEY,
  reactOptions: {
    useCamelCaseFlagKeys: false
  }
})(App);
