import PropTypes from 'prop-types';
import { Checkbox, Icon, Tooltip } from "@shopify/polaris";
import styles from './InputCheckbox.module.css'
import { WIX } from '../../../Constant';
import { QuestionIcon } from '@chakra-ui/icons';
import { Checkbox as ChakraCheckbox, Tooltip as ChakraTooltip } from '@chakra-ui/react'
import { useContext } from 'react';
import { PageData } from '../../../ContextAPI/context';
import { QuestionMarkMinor } from '@shopify/polaris-icons';

const InputCheckbox = ({ label, checked, onChange, showTooltip, tooltipText, disabled, helpText }) => {
    const { type } = useContext(PageData)

    return (
        <div className={styles.checkbox_wrap}>
            <div className="ue__icon_set">
                {type === WIX ?
                    <div className={styles.checkbox_field}>
                        <ChakraCheckbox className={styles.chakra_checkbox} checked={checked} readOnly={disabled} defaultChecked={checked} onChange={(e) => { if (!disabled) onChange(e.target.checked) }}>{label}</ChakraCheckbox>

                        {showTooltip &&
                            <ChakraTooltip label={tooltipText}>
                                <span className="ue_infolabel">
                                    <QuestionIcon width={'16px'} height={'16px'} />
                                </span>
                            </ChakraTooltip>
                        }

                    </div>
                    :
                    <div className={styles.checkbox_field}>
                        <Checkbox
                            label={label}
                            checked={checked}
                            onChange={(e) => { if (!disabled) onChange(e) }}
                        />

                        {showTooltip && (
                            <Tooltip content={tooltipText}>
                                <span className="ue_infolabel">
                                    <Icon source={QuestionMarkMinor} color="base" />
                                </span>
                            </Tooltip>
                        )}
                    </div>
                }
            </div>
            {helpText && <div className={type === WIX ? styles.wix_help_text : styles.help_text} dangerouslySetInnerHTML={{ __html: helpText }} />}
        </div>
    );
}

InputCheckbox.prototype = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func
}

export default InputCheckbox