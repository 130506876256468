import React, { useContext, useEffect, useState } from 'react';
import Title from './Title';
import Product from './Product';
import Vendor from './Vendor';
import Image from './ImageComponent';
import Price from './Price';
import VariantPicker from './VariantPicker';
import QuantitySelector from './QuantitySelector';
import Buttons from './Buttons';
import Description from './Description';
import OfferSetting from './globalSettings/OfferSSetting';
import SelectorArea from './SelectorArea';
import { PageData } from '../ContextAPI/context';
import { getTargeted } from '../Actions/common';
import Heading from './Heading';
import ProductDetails from "./ProductDetails";
import Divider from './DividerComponent';
import UpsellWidget from './UpsellWidget';
import ProductUpsell from './ProductUpsell';
import CustomizeSection from './CustomizeSection';
import { BACK, CHECKOUT, PRODUCT_UPSELL, UPDATE_STATE, WIX } from '../Constant';
import SuccessErrorBanner from './SuccessErrorBanner';
import RightColumn from './RightColumn';
import LeftColumn from './LeftColumn';
import RightBarSkeleton from './RightBarSkeleton';
import BannerSection from './BannerSection';
import { CDN_BASE_URL } from '../config';
import CustomHeader from './commonComp/CustomHeader';
import { Icon } from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';

const _ = require('lodash');

function RightSideBar(props) {
  const { PrePurchaseConfigSetting, unifiedEditor, dispatch, selectedPage, rightbarLinks, setSelectedLink, setBannerStatus, checkIsAnySectionPresent, type, isRequiredDataFilled, handleEditorChanges } = useContext(PageData);
  const { main, sub, count, minCount, target, id, sectionID, topSectionID, extentionID } = props.renderComponent;
  const { loading, setLoading } = props
  const [rightbar, rightState] = useState();

  const { backButton } = props

  useEffect(() => {
    rightState(main);
  }, [main, unifiedEditor, PrePurchaseConfigSetting]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false)
      }, 300)
    }
  }, [main, target, id, sectionID, topSectionID, extentionID, setLoading, loading])

  useEffect(() => {
    setBannerStatus({})
  }, [main, id, setBannerStatus])

  useEffect(() => {
    backButton(BACK);
  }, [selectedPage, backButton])

  const switchdata = () => {
    switch (rightbar) {
      case 'Divider':
        return <Divider type={props.renderComponent} />;
      case 'Multi-product upsell':
        return <UpsellWidget type={props.renderComponent} />;
      case 'Top (Left column)':
        return <LeftColumn type={props.renderComponent} />;
      case 'Bottom (Right column)':
        return <RightColumn type={props.renderComponent} />;
      case "Product upsell":
        return <ProductUpsell type={props.renderComponent} />;
      case 'Heading':
        return <Heading type={props.renderComponent} />;
      case 'Text':
        return <Heading type={props.renderComponent} />;
      case 'Price and discount':
        return <Price type={props.renderComponent} />;
      case 'Title':
        return <Title type={props.renderComponent} />;
      case 'Variant Picker':
        return <VariantPicker type={props.renderComponent} />;
      case 'Option picker':
        return <VariantPicker type={props.renderComponent} />;
      case 'Description':
        try {
          return <Description type={props.renderComponent} />;
        } catch (err) { }
        break;
      case 'Product Description':
        return <Description type={props.renderComponent} />;
      case 'Quantity Selector':
        return <QuantitySelector type={props.renderComponent} />;
      case 'Price':
        return <Price type={props.renderComponent} />;
      case 'Vendor':
        return <Vendor type={props.renderComponent} />;
      case 'Product':
        return <Product type={props.renderComponent} />;
      case 'Button':
        return <Buttons type={props.renderComponent} />;
      case 'Image':
        return <Image type={props.renderComponent} />;
      case 'Settings':
        return <OfferSetting type={props.renderComponent} />;
      case "Product Details":
        return <ProductDetails type={props.renderComponent} />;
      case "Success/Error banner":
        return <SuccessErrorBanner type={props.renderComponent} />;
      case "Banner":
        return <BannerSection type={props.renderComponent} />;
      case undefined:
        return sub === undefined ? '' : <CustomizeSection />;
      default:
        return <CustomizeSection />;
    }
  };

  const removeElement = async (key, value, newTarget = target, isRemoved, editedData) => {
    let latestData = _.cloneDeep(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
    const previewData = getTargeted(latestData, newTarget, 1, id, sectionID, topSectionID, extentionID)
    let index = previewData.findIndex((item) => Number(item[key]) === Number(value))

    if (index >= 0) {
      if (sectionID && topSectionID && previewData.length === 1 && !isRemoved) {
        newTarget = newTarget.split('.');
        newTarget.splice(newTarget.length - 2, 2);
        editedData = handleEditorChanges('remove', previewData)
        removeElement(key, topSectionID, newTarget.join('.'), true, editedData)

      } else {
        if (!isRemoved) {
          if (!sectionID && topSectionID) {
            editedData = handleEditorChanges('remove', previewData[index].sections)
          } else if (!isRemoved && sectionID && key !== 'id') {
            editedData = handleEditorChanges('remove', [previewData[index]])
          } else if (key === 'id') {
            editedData = handleEditorChanges('edit', { type: PRODUCT_UPSELL, sectionID })
          }
        }

        previewData.splice(index, 1);
        dispatch({ type: UPDATE_STATE, payload: { ...latestData, editedData } });

        if (type === CHECKOUT && key === 'sectionID') {
          checkIsAnySectionPresent(latestData)
        }

        props.backButton(BACK);
      }
    }
  };

  const handleLinkClick = (obj) => {
    if (isRequiredDataFilled()) {
      setSelectedLink(obj)
    }
  }

  const getRemoveButton = (key, label, value) => {
    return (
      <div className="bottom_container">
        <div className="remove_button fontStyle" onClick={() => removeElement(key, value)}>
          {type === WIX ?
            <>
              <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z" fill="#d72c0d" />
              </svg>
              <span>Remove {label}</span>
            </>
            :
            <>
              <Icon source={DeleteMinor} color="base" />
              <span>Remove {label}</span>
            </>
          }
        </div>
      </div>
    )
  }

  return (
    <div className='right_container'>
      <div className="right-section overflow_vertical_scroll">
        <SelectorArea>
          <div className="right-section-title">
            <div
              className="back-arrow"
              onClick={() => {
                if (isRequiredDataFilled()) {
                  props.backButton(BACK);
                }
              }}
            >
              <img src={CDN_BASE_URL + `assets/chevron-left.svg`} alt={`chevron left icon`} />
            </div>
            <CustomHeader label={main && main} isHideLink={true} />
          </div>
          {loading ? <RightBarSkeleton /> : <div>{switchdata()}</div>}

          {rightbarLinks && rightbarLinks.length > 0 &&
            <div className='links-container'>
              <div className='link-divider' />
              <div className='link-title'>Edit {!sectionID ? 'sections' : 'blocks'} in this  {!sectionID ? 'widget' : 'section'} </div>
              {rightbarLinks.map((item, i) => {
                return <div key={i} className='link-content' onClick={() => handleLinkClick(item)}>{item.name}</div>
              })}
            </div>
          }

        </SelectorArea>
      </div>

      <div className='bottom_remove_buttons'>

        {(minCount === 0 || count > minCount) && id && (
          getRemoveButton('id', 'block', id)
        )}

        {(sectionID || topSectionID) && !id && rightbar && (minCount === 0 || count > minCount) &&
          getRemoveButton('sectionID', 'section', sectionID ? sectionID : topSectionID)
        }
      </div>
    </div>
  );
}

export default RightSideBar;
