import axios from 'axios';
import { BASE_URL, MICROSERVICE_BASE_URL } from '../config';
import { AUTH_TOKEN } from '../Constant';

const getToken = () => {
  return 'Bearer ' + localStorage.getItem(AUTH_TOKEN)
}

const getURLBasedOnType = (commonURL, isMicroService) => {
  let url = BASE_URL + `unified_editor/shopify/${commonURL}`
  if (isMicroService) {
    url = MICROSERVICE_BASE_URL + `store/${commonURL}`
  }
  return url
}

// verify user 
export const verifyAuth = async (isMicroService, res, err) => {
  let url = BASE_URL + `auth/verify_hmac${window.location.search}`
  if (isMicroService) {
    url = MICROSERVICE_BASE_URL + `auth/verify_hmac${window.location.search}`
  }

  await axios({
    method: 'get',
    url: url,
  })
    .then(response => res(response))
    .catch(error => err(error));
};

export const fetchShopifyProducts = async (nextPageToken, isMicroService) => {
  const limit = 100000;
  let url = getURLBasedOnType(`products?limit=${limit}${nextPageToken ? '&page_info=' + nextPageToken : ''}`, isMicroService)
  const response = await axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: getToken()
    }
  });

  return response;
};

export const fetchShopifyProductsWithSearch = async (q, endCursor, limit, isMicroService) => {
  let url = getURLBasedOnType(`products?limit=${limit}&onlyActive=1${endCursor ? `&endCursor=${endCursor}` : ''}${q ? `&q=${q}` : ''}`, isMicroService)

  const response = await axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: getToken()
    }
  });

  return response;
};

export const fetchShopifyProduct = async (productId, isMicroService) => {
  let url = getURLBasedOnType(`products/${productId}`, isMicroService)

  const response = await axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: getToken()
    }
  });

  return response;
};

export const uploadShopifyProductImages = async (files, isMicroService) => {

  const formData = new FormData();

  for (let file of files) {
    formData.append('files', file);
  }

  let url = BASE_URL + `unified_editor/shopify/assets/upload`
  if (isMicroService) {
    url = MICROSERVICE_BASE_URL + `store/assets/upload`
  }

  const response = await axios({
    method: 'post',
    url: url,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: getToken()
    }
  });

  return response;
};

export const fetchShopifyProductImages = async (isMicroService) => {

  let url = BASE_URL + `unified_editor/shopify/assets/images`
  if (isMicroService) {
    url = MICROSERVICE_BASE_URL + `store/assets/images`
  }

  const response = await axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: getToken()
    }
  });

  return response;
};

export const fetchPreviews = async (verifyToken, isMicroService, type, callback) => {

  let url = BASE_URL + `preview/${type}`
  if (isMicroService) {
    url = MICROSERVICE_BASE_URL + `editor/${type}`
  }
  let token = verifyToken

  await axios({
    method: 'get',
    url: url,
    headers: {
      Authorization: 'Bearer ' + token
    }
  }).then(response => callback(response));
};

// update preview data to DB by type
export const updatePreview = async (payload, isMicroService, type, callback, err) => {

  let url = BASE_URL + `checkout_extensions/${payload.id}?type=${type}`
  if (isMicroService) {
    url = MICROSERVICE_BASE_URL + `editor/${payload.id}?type=${type}`
  }

  await axios({
    method: 'PUT',
    url: url,
    data: JSON.stringify(payload),
    headers: {
      'content-type': 'application/json',
      Authorization: getToken()
    }
  })
    .then(response => callback(response))
    .catch(error => err(error));
};


export const sendSegmentIdentify = async (payload, callback, err) => {
  let url = MICROSERVICE_BASE_URL + `segments/identify`

  await axios({
    method: 'POST',
    url: url,
    data:  JSON.stringify(payload),
    headers: {
      'content-type': 'application/json',
      Authorization: getToken()
    }
  })
    .then(response => callback(response))
    .catch(error => err(error));
};

export const trackSegmentsService = async (payload, callback, err) => {

  let url = MICROSERVICE_BASE_URL + `segments/track`

  await axios({
    method: 'POST',
    url: url,
    data: JSON.stringify(payload),
    headers: {
      'content-type': 'application/json',
      Authorization: getToken()
    }
  })
    .then(response => callback(response))
    .catch(error => err(error));
};