import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react'
import { useState, useCallback, useEffect, useContext } from 'react';
import { PageData } from '../../ContextAPI/context';

const AddUpsellPopup = () => {
    const [active, setActive] = useState(false);
    const { isaddUpsellModalOpen, setIsaddUpsellModalOpen, setAddStaticUpsell } = useContext(PageData)

    useEffect(() => {
        if (isaddUpsellModalOpen === true) {
            setActive(true)
        }
    }, [isaddUpsellModalOpen])

    const handleChange = useCallback(() => {
        setIsaddUpsellModalOpen()
        setActive(false)
    }, [setIsaddUpsellModalOpen]);

    const handleSubmit = () => {
        setAddStaticUpsell(true)
        handleChange()
    }

    const title = "Start customizing your checkout"
    const body = "Add upsell to the checkout page in one click"
    const saveButtonText = "Add upsell"

    return (
        <div>
            <Modal onClose={handleChange} size={'xl'} isOpen={active} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {body}
                    </ModalBody>
                    <ModalFooter>
                        <Stack spacing={3} direction='row' align='center'>
                            <Button colorScheme='gray' variant='outline' onClick={handleChange}>Close</Button>
                            <Button colorScheme='teal' variant='solid' onClick={handleSubmit}>{saveButtonText}</Button>
                        </Stack>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </div>
    );
}

export default AddUpsellPopup